import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { SectionTitle } from 'TS_components/HomePage/HomePage.styled';

export const BlogSection = styled.section`
  padding-bottom: 75px;
  ${media.max.md`
    padding-bottom: 55px;
  `}
`;

export const SectionTitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 15px 0 !important;
  gap: 20px;
  ${SectionTitle} {
    margin: 0 !important;
  }

  ${media.max.sm`
    justify-content: space-between;
  `}
`;

export const SectionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  all: unset;
  border: 1px solid #282828;
  border-radius: 5px;
  color: #282828 !important;
  padding: 3px 10px;
  font-size: 12px !important;
  font-weight: 700 !important;
  cursor: pointer;
`;

export const BlogSectionText = styled.p`
  margin: 0;
  font-size: ${({ theme }) => theme.fontsizes.body16} !important;
  color: ${({ theme }) => theme.colors.textGray} !important;
  margin: -5px 0 0 0 !important;
  ${media.max.md`
    margin: -5px 0 0 0 !important;
  `}
`;

export const BlogCardsList = styled.div`
  display: flex;
  /* gap: 25px; */
  padding: 15px !important;
  margin-left: -15px;
  overflow-y: auto;
  margin-top: -3px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  ${media.max.md`
    margin-top: -5px;
    // gap: 15px;
  `}
`;

export const ViewAllContainer = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;

export const ViewAllLink = styled.a`
  cursor: pointer;
  border-radius: 35px;
  background-color: #2a3390 !important;
  border: none;
  padding: 9px 16px /*rtl:12px 15px 6px 18px */;
  transform: rotate(0deg) /*rtl:rotate(180deg)*/;
  margin-bottom: 10px;
`;

export const ViewAllText = styled.div`
  color: #2a3390;
  font-size: 12px;
  font-weight: 600;
  width: 90px;
  text-align: center;
`;
