import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';

export const InsuranceSection = styled.section`
  /* margin-bottom: 105px; */
  padding-bottom: 75px;
  margin-top: -15px;
  ${media.max.md`
    padding-bottom: 55px;
  `}
`;
export const SectionTitleContainer = styled.div`
  display: flex;
`;

export const InsuranceCardSection = styled.div`
  display: flex;

  &::-webkit-scrollbar {
    display: none;
  }
  /* -ms-overflow-style: none;
  scrollbar-width: none; */
`;

export const InsuranceCardsList = styled.div`
  display: flex;
`;

export const InsuranceEmptyList = styled.div`
  padding: 50px 0px;
  color: #a2a3a6;
  margin-left: 15px;
`;
export const InsuranceSearchContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 345px;
  height: 45px;
  position: relative;
  background-color: #fff;
  border: 1px solid #ededed;
  border-radius: 5px;
  overflow: hidden;
  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
    pointer-events: none;
  }
`;

export const StyledSearchInput = styled.input`
  outline: none;
  width: 100%;
  height: 100%;
  border: 0px;
  background-color: #fff !important;
  width: 100%;
  padding: 0 15px 0 40px;
`;
