import { localize } from "@/utils";
import { Mixpanel } from "lib/Mixpanel";
import { useRouter } from "next/router";
import { FC } from "react";
import { useSelector } from "react-redux";
import { Anchor, CardTitle, SpecialityCard, TitleContainer } from "./PopularSpecialityCard.styles";

interface SpecialityCardProps {
  title: string;
  link: string;
  Svg: FC; // we pass the SVG as a styled component to allow rendering any svg
  mixpanelName: string;
}

const PopularSpecialityCard: FC<SpecialityCardProps> = ({ title, link, Svg, mixpanelName }: SpecialityCardProps) => {
  const { locale: language } = useRouter(); // change locale to language to avoid confusion in mixpanel
  const { client_side_country } = useSelector(({ InitialDataS }: any) => InitialDataS);
  const localizedLink = `/${localize(`doctors/${link}`, client_side_country, language)}`;

  // todo: maybe move mixpanel to its own directory => utils/mixpanel/homepage.js?
  const trackMixpanel = () => {
    const eventName = "Homepage | Popular Speciality Clicked | Link";
    const eventProps = {
      "Speciality Name": mixpanelName,
      Locale: client_side_country || "qatar",
      Language: language.toUpperCase(),
    };
    return Mixpanel.track(eventName, eventProps);
  };

  return (
    <Anchor onClick={trackMixpanel} data-cy={link} aria-label={title} href={localizedLink}>
      <SpecialityCard>
        <Svg />
        <TitleContainer>
          <CardTitle>{title}</CardTitle>
        </TitleContainer>
      </SpecialityCard>
    </Anchor>
  );
};

export default PopularSpecialityCard;
