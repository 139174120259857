export const specPaths = [
  {
    name_en: 'obstetrician+and+gynecologist',
    name_ar: 'نساء+وولادة',
    src: '/images/OBGYN.svg',
    alt: 'OBGYN Icon',
    text: 'Obstetricians & Gynecologists',
    mixpanel_name: 'Obstetricians & Gynecologists',
    iconName: 'OBGYNIcon',
  },
  {
    name_ar: 'طبيب+أطفال',
    name_en: 'pediatrician',
    src: '/images/Pediatrician.svg',
    alt: 'Pediatrician Icon',
    text: 'Pediatricians',
    mixpanel_name: 'Pediatricians',
    iconName: 'PediatricianIcon',
  },
  {
    name_ar: 'طبيب+عام',
    name_en: 'general+practitioner',
    src: '/images/General Doctors.svg',
    alt: 'General Doctor Icon',
    text: 'General Doctors',
    mixpanel_name: 'General Doctors',
    iconName: 'GeneralDoctorIcon',
  },
  {
    name_ar: 'طبيب+الأسنان+العام',
    name_en: 'general+dentist',
    src: '/images/Dentist.svg',
    alt: 'Dentist Icon',
    text: 'Dentists',
    mixpanel_name: 'General Dentists',
    iconName: 'DentistIcon',
  },
  {
    name_ar: 'طبيب+جلدية',
    name_en: 'dermatologist',
    src: '/images/Dermatologist.svg',
    alt: 'Dermatologist Icon',
    text: 'Skin Doctors',
    mixpanel_name: 'Skin Doctors',
    iconName: 'SkinDoctorIcon',
  },
  {
    name_en: 'ophthalmologist',
    name_ar: 'طبيب+عيون',
    src: '/images/Ophthalmologist.svg',
    alt: 'Ophthalmologist Icon',
    text: 'Eye Doctors',
    mixpanel_name: 'Eye Doctors',
    iconName: 'EyeDoctorIcon',
  },
];
