import useTranslation from "intl/useTranslation";
import { Mixpanel } from "lib/Mixpanel";
import Link from "next/link";
import { useSelector } from "react-redux";
import { ListingDoctor } from "types/Doctors";
import { localize } from "utils/url";
import { useDoctorData } from "./DoctorCard.hooks";
import { CTA, DoctorCredentials, DoctorTags, ImageInfo } from "./DoctorCard.parts";
import { CTAGrouping, DoctorCard, DoctorCardGrouping, DoctorCardLink, Note } from "./PopularDoctorCard.styles";

interface Props {
  doctorData: ListingDoctor;
}
const PopularDoctorCard = ({ doctorData }: Props) => {
  const { t } = useTranslation();
  const { doctorInfo, doctorCredentials, CTAData } = useDoctorData(doctorData);
  const { client_side_country, language_code } = useSelector(({ InitialDataS }: any) => InitialDataS);

  const doctorCardClickEvent = (e) => {
    const eventName = "Doctor Card | Card Clicked | Card";
    const eventProps = {
      "Page Type": "Home Page",
      Locale: client_side_country || "qatar",
      "Speciality Name": doctorInfo.specialization,
      "Doctor Name": doctorInfo.name_en,
    };
    Mixpanel.track(eventName, eventProps);
  };

  return (
    <Link href={`/${localize(`${doctorData.slug}`, client_side_country, language_code)}`}>
      <DoctorCardLink>
        <DoctorCard onClick={doctorCardClickEvent} data-cy="doctor-card">
          <DoctorCardGrouping>
            <DoctorTags
              offer={doctorData.offer_list.length > 0}
              popular={doctorData.popular}
              videoVisit={doctorData.visit_types.length > 1}
            />
            <ImageInfo info={doctorInfo} />
          </DoctorCardGrouping>
          <DoctorCredentials credentials={doctorCredentials} />
          <CTAGrouping>
            {CTAData.videoVisit && <Note>{t("Offers clinic & video visits")}</Note>}
            <CTA data={CTAData} />
          </CTAGrouping>
        </DoctorCard>
      </DoctorCardLink>
    </Link>
  );
};

export default PopularDoctorCard;
