import { localize } from "@/utils";
import Axios from "lib/Axios";
import { HomePageData } from "types/Home";

export const _GetHomePage = ({ country, locale }: { country: string | null; locale: string }) =>
  Axios.get<HomePageData>(localize("/", country, locale)).then((res) => res.data);

export const _GetPopularClinics = ({ country, locale, slug }: { country: string; locale: string; slug: string }) =>
  Axios.post<any>(localize(`/clinics/${slug}`, country, locale), { data: {} }).then((res) => res.data);

export const _GetCitiesWithDoctors = ({ country, locale, slug }: { country: string; locale: string; slug: string }) =>
  Axios.get<any>(localize(`/cities/${slug}`, country, locale), {}).then((res) => res.data);

// export const _GetHomePageRedirection = (clientIp: string) =>
//   fetch(`https://ip.nf/${clientIp}.json`)
//     .then((response) => response.json())
//     .then((data) => data.ip.country_code);
