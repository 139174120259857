import { localizeUrl } from "@/utils";
import useTranslation from "intl/useTranslation";
import { Mixpanel } from "lib/Mixpanel";
import Image from "next/image";
import { useRouter } from "next/router";
import { FC } from "react";
import { useSelector } from "react-redux";
import { Insurance } from "types/Insurance";
import {
  InsuranceCardContainer,
  InsuranceEmptyNetworks,
  InsuranceEmptyNetworksText,
  InsuranceHeaderIcon,
  InsuranceHeaderName,
  InsuranceHeaderRow,
  InsurancePlan,
  InsurancePlansContainer,
} from "./InsuranceCard";
interface InsuranceCardProps {
  insurance: Insurance;
}

const InsuranceCard: FC<Partial<InsuranceCardProps>> = ({ insurance }: InsuranceCardProps) => {
  const { t } = useTranslation();
  const { client_side_country, language_code } = useSelector(({ InitialDataS }: any) => InitialDataS);
  const { push } = useRouter();
  const trackMixpanel = (eventName, eventProps) => {
    return Mixpanel.track(eventName, eventProps);
  };

  return (
    <InsuranceCardContainer
      onClick={() => {
        trackMixpanel("Insurance Section | Insurance Card Clicked | Card", {
          "Page Type": "Home Page",
          "Insurance Name": insurance.name_en,
        });
        push(`${client_side_country ? `/${client_side_country}/` : "/"}insurance/${insurance.slug_en}`);
      }}
    >
      <InsuranceHeaderRow>
        <InsuranceHeaderName>{insurance.name}</InsuranceHeaderName>
        <InsuranceHeaderIcon>
          <Image
            src={`${localizeUrl(`insurance/pic/${insurance.id}/78/32/95/`, client_side_country, language_code)}`}
            alt={`${insurance.name} logo`}
            layout="fixed"
            width="80"
            height="25"
          />
        </InsuranceHeaderIcon>
      </InsuranceHeaderRow>
      {insurance?.networks.length ? (
        <InsurancePlansContainer>
          <>
            {insurance.networks.slice(0, 5).map((network) => {
              return <InsurancePlan>{network.name.slice(0, 20)}</InsurancePlan>;
            })}
            <InsurancePlan
              isShowMore={true}
              onClick={() => {
                push(`insurance/${insurance.slug_en}`);
              }}
            >
              {t("Show more")}
            </InsurancePlan>{" "}
          </>
        </InsurancePlansContainer>
      ) : (
        <InsuranceEmptyNetworks>
          <InsuranceEmptyNetworksText>{t("No plans to show")}</InsuranceEmptyNetworksText>
        </InsuranceEmptyNetworks>
      )}
    </InsuranceCardContainer>
  );
};

export default InsuranceCard;
