import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';

export const BlogCardWrapper = styled.article`
  display: flex;
  min-width: 320px;
  width: 100%;
  margin-left: 25px;
  &:first-child {
    margin-left: 0;
  }
  ${media.max.sm`
    margin-left: 15px;
    &:first-child {
    margin-left: 0;
  }
  `}
`;
export const BlogCardLink = styled.a`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white} !important;
  box-shadow: 0px 4.5px 8.5px rgba(85, 85, 85, 0.25);
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none !important;
  height: 100%;
`;
export const BlogCardImg = styled.img`
  display: block;
  object-fit: cover;
  width: 100%;
  min-height: 190px;
  max-height: 190px;
  ${media.max.md`
    min-height: 165px;
    max-height: 165px;
  `}
`;
export const BlogCardContent = styled.div`
  padding: 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
  ${media.max.md`
    padding: 10px;
  `}
`;
export const BlogCardTitle = styled.h3`
  margin: 0 0 5px 0 !important;
  font-size: ${({ theme }) => theme.fontsizes.body18} !important;
  font-weight: ${({ theme }) => theme.fontWeight.font600} !important;
  color: ${({ theme }) => theme.colors.textDark} !important;
  line-height: 1.5 !important;
`;
export const BlogCardDesc = styled.p`
  font-size: ${({ theme }) => theme.fontsizes.body16} !important;
  font-weight: ${({ theme }) => theme.fontWeight.font300} !important;
  color: ${({ theme }) => theme.colors.textDarkGray} !important;
  line-height: 1.5 !important;
  margin: 0 0 auto 0 !important;
`;
export const BlogCardReadMore = styled.span`
  font-size: ${({ theme }) => theme.fontsizes.body16} !important;
  font-weight: ${({ theme }) => theme.fontWeight.font600} !important;
  color: ${({ theme }) => theme.colors.generalLink} !important;
  line-height: 1.5 !important;
  margin: 15px 0 0 0 !important;
`;
