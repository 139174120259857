import { media } from "styled-bootstrap-grid";
import styled from "styled-components";
export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
`;

export const BMGFHeader = styled.div`
  margin-top: 30px;
  margin-bottom: 50px;
`;

export const BMGFHeading = styled.p`
  font-weight: 800 !important;
  font-size: 22px !important;
  color: #282828;
`;

export const BMGFSubHeading = styled.p`
  margin-top: 5px;
  font-weight: 600 !important;
  font-size: 15px !important;
  color: #a2a3a6 !important;
`;

export const BMGFFooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  /* Height of the footer*/
  height: 76px;
  background: #ffffff;
  border: 1px solid #e7e9ef;
  z-index: 9;
`;

export const BMGFNextButton = styled.button`
  margin-left: 30px !important;
  background: #2a3390;
  border-radius: 5px;
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #ffffff !important;
  padding: 11px 32px;
  border: none;
  ${media.max.sm`
    margin: 0px 15px 0px 10px !important;
  `}
`;

export const BMGFEndButton = styled.button`
  margin-left: 30px !important;
  background: #2a3390;
  border-radius: 5px;
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #ffffff !important;
  padding: 11px 32px;
  border: none;
  ${media.max.sm`
    margin: 0px 15px 0px 10px !important;
     padding: 13px 62px 13px 64px;
  `}
`;

export const BMGFBackButton = styled.button`
  margin-left: 30px !important;
  background: #ffffff;
  border-radius: 5px;
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #282828 !important;
  padding: 11px 32px;
  border: 0.85px solid #282828;
`;

export const BMGFFooterGroup = styled.div<{ isMobileSubmit?: boolean; isMobile?: boolean }>`
  padding: 10px 136px 10px 0px;
  float: right;
  display: flex;
  align-items: center;

  justify-content: ${({ isMobileSubmit, isMobile }) =>
    isMobile ? (isMobileSubmit ? "center" : "space-between") : "auto"};
  ${media.max.sm`
    padding: 0px;
    width: 100%;
    
    align-items:normal;
  `}
`;

export const BMGFFooterStepperContainer = styled.div`
  display: flex;
  ${media.max.sm`
      margin-top:34px;
      margin-left:5px
  `}
`;

export const BMGFFooterButtonContainer = styled.div`
  ${media.max.sm`
      margin-top:15px;
  `}
`;

export const BMGFFooterStepper = styled.div<{ selected?: boolean; previous?: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${({ selected, previous }) => (selected ? (previous ? "#fff" : "#2a3390") : "#DDD")};
  border: ${({ previous }) => (previous ? "1px solid #2A3390" : "none")};
  margin-left: 10px;
`;

export const BMGFEndContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  margin-bottom: 40px;
`;
export const BMGFEndContainerText = styled.span`
  font-weight: 700;
  font-size: 13px;
  color: #2a3390;
`;

export const BMGFPhoneInputContainer = styled.div`
  .bmgf-tel-input {
    border: 0px !important;
    border-bottom: 1px solid #a2a3a6 !important;
    border-radius: 0px !important;
    box-shadow: none !important;
  }
  z-index: 1;
  margin-bottom: 30px;
`;

export const BMGFPhoneInputHeader = styled.p`
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #282828 !important;
  margin-bottom: 7px !important;
`;
export const BMGFPhoneInputFooter = styled.p`
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #282828 !important;
  margin-top: 10px !important;
`;

export const BMGFPhoneInputError = styled.p`
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #d73e3e !important;
  margin: 10px 0px 0px 0px !important;
`;

export const BMGFOtpContainer = styled.div``;
