import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';

export const PopularSection = styled.section`
  width: 100% !important;
  padding: 15px 0 90px 0 !important;
  ${media.max.md`
    padding: 15px 0 70px 0 !important;
  `}
`;
export const CardContainer = styled.div`
  display: flex !important;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  /* gap: 30px 0; */

  ${media.max.lg`
    gap: 23px 0;
  `}

  ${media.max.sm`
    gap: 0;
  `}
`;
