import useTranslation from 'intl/useTranslation';
import { FC } from 'react';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import {
  DownLoadAppButtonsDesktop,
  DownLoadAppButtonsMobile,
  DownLoadAppDescription,
  DownLoadAppIconContainer,
  DownloadAppLinkContainer,
  DownloadAppReactangleText,
  DownloadSectionContent,
} from './DownloadAppSection';
import { DownloadAppButtons } from './DownloadAppSection.parts';
interface DownLoadAppSectionProps {}

const DownLoadApp: FC<Partial<DownLoadAppSectionProps>> = ({}: DownLoadAppSectionProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <Row>
          <Col col={12}>
            <DownloadAppLinkContainer>
              <DownloadSectionContent>
                <DownloadAppReactangleText>
                  <span>{t('Download Our App')}</span>
                </DownloadAppReactangleText>
                <DownLoadAppDescription>
                  {t('Download our app today and get the best of HeliumDoc. Your wellness is just a tap away!')}
                </DownLoadAppDescription>
                <DownLoadAppButtonsDesktop>
                  <DownloadAppButtons
                    appleUrl={'https://apps.apple.com/app/heliumdoc/id1499476564'}
                    googleUrl={'https://play.google.com/store/apps/details?id=com.heliumhealth.myhelium'}
                  />
                </DownLoadAppButtonsDesktop>
              </DownloadSectionContent>
              <div>
                <DownLoadAppIconContainer>
                  <img src="/next-images/Download-App.svg" />
                </DownLoadAppIconContainer>
                <DownLoadAppButtonsMobile>
                  <DownloadAppButtons
                    appleUrl={'https://apps.apple.com/app/heliumdoc/id1499476564'}
                    googleUrl={'https://play.google.com/store/apps/details?id=com.heliumhealth.myhelium'}
                  ></DownloadAppButtons>
                </DownLoadAppButtonsMobile>
              </div>
            </DownloadAppLinkContainer>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DownLoadApp;
