import styled from "styled-components";

export const MultiInputContainer = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;
export const MultiInputQuestionHeader = styled.div`
  display: flex;
`;
export const MultiInputQuestionNumber = styled.p`
  font-size: 16px !important;
`;
export const MultiInputLabel = styled.span`
  font-size: 16px !important;
  font-weight: bold !important;
  margin-left: 5px !important;
`;

export const MultiInputParent = styled.div`
  display: flex;
  align-items: center;
`;

export const MultiInputItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MultiInputItem = styled.div`
  height: 20px;
  margin: 15px 0px 0px 0px;
  display: table;
  &:first-child {
    margin-top: 0px;
  }
`;

export const MultiInputError = styled.p`
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #d73e3e !important;
  margin: 10px 0px 0px 0px !important;
`;
