import useTranslation from '@/intl/useTranslation';
import { getCompleteUrl } from '@/utils';
import { BlogPost } from 'types/Home';
import {
  BlogCardContent,
  BlogCardDesc,
  BlogCardImg,
  BlogCardLink,
  BlogCardReadMore,
  BlogCardTitle,
  BlogCardWrapper,
} from './BlogCard.styled';

type Props = {
  data: BlogPost;
  onClick?: () => void;
};

export default function BlogCard({ data, onClick }: Props) {
  const { t } = useTranslation();

  return (
    <BlogCardWrapper onClick={() => (onClick ? onClick() : null)}>
      <BlogCardLink href={data.url} rel="noopener" target="_blank">
        <BlogCardImg src={getCompleteUrl(`${data.image_url}`)} alt={data.title} />
        <BlogCardContent>
          <BlogCardTitle>{data.title}</BlogCardTitle>
          <BlogCardDesc>{data.short_desc.slice(0, 80) + '...'}</BlogCardDesc>
          <BlogCardReadMore>{t('Read More')}</BlogCardReadMore>
        </BlogCardContent>
      </BlogCardLink>
    </BlogCardWrapper>
  );
}
