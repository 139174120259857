import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';

export const DownloadAppLinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 120px;
  ${media.max.md`
    flex-direction: column;
    margin-bottom: 70px;
  `}
`;
export const DownloadSectionContent = styled.div`
  margin-bottom: -25px;
  ${media.max.md`
    margin-bottom: 0;
  `}
`;
export const DownLoadAppIconContainer = styled.div`
  display: flex;
  max-width: 470px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  ${media.max.md`
    max-width: 323.59px;
    margin-top: 5px;
  `}
`;

export const DownLoadAppDescription = styled.p`
  margin: 15px 0 0 0 !important;
  color: ${({ theme }) => theme.colors.textGray} !important;
  font-size: 2rem !important;
  line-height: 1.25 !important;
  max-width: 570px;
  ${media.max.md`
    margin: 10px 0 0 0 !important;
    font-size: 1.6rem !important;
    line-height: 1.5 !important;
  `}
`;

export const DownLoadAppButtonsDesktop = styled.div`
  margin-top: 80px;
  display: flex;
  gap: 15px;
  ${media.max.md`
    position: absolute;
    opacity: 0;
    visibility: hidden;
  `}
`;
export const DownLoadAppButtonsMobile = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 15px;
  ${media.md`
    display: none;
  `}
`;

export const DownLoadAppButton = styled.button`
  all: unset;
  min-width: 150px;
  max-width: 150px;
  min-height: 50px;
  max-height: 50px;
  line-height: 0;
  ${media.max.md`
    min-width: 126px;
    max-width: 126px;
    min-height: 42px;
    max-height: 42px;
    svg {
      transform: scale(0.84);
      transform-origin: 0 0;
    }
  `}
`;

export const DownloadAppReactangleText = styled.div`
  font-size: ${({ theme }) => theme.fontsizes.h2} !important;
  font-weight: ${({ theme }) => theme.fontWeight.font700} !important;
  color: ${({ theme }) => theme.colors.white} !important;
  padding: 0 25px 0 0;
  position: relative;
  display: inline-flex;
  align-items: center;
  min-height: 68px;
  line-height: 1.25 !important;
  span {
    z-index: 1;
    position: relative;
  }
  &::before {
    content: '';
    background-color: #f81e46;
    position: absolute;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100%;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  ${media.max.md`
    min-height: 40px;
    padding: 0 16px 0 0;
    font-size: 2rem !important;
  `}
`;
