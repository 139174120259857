import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

export const BMGFCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 40px;
  align-items: center;
  ${media.max.sm`
    width:100%;
    margin-top: 30px;
  `}
`;

export const BMGFCodeHeader = styled.p`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.max.sm`
    align-items: flex-start;
  `}
`;
export const BMGFCodeHeading = styled.p`
  font-weight: 800 !important;
  font-size: 22px !important;
  color: #282828;
  ${media.max.sm`
    margin-bottom: 5px !important;
  `}
`;

export const BMGFCodeHeadingText = styled.p`
  font-weight: 600 !important;
  font-size: 15px !important;
  color: #a2a3a6 !important;
`;

export const BMGFCodeInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 150px;
  width: 75%;
  ${media.max.md`
    width: 100%;
  `}
  ${media.max.sm`
    margin-top: 100px ;
  `}
`;

export const BMGFCodeInputParent = styled.div`
  margin-bottom: 20px;
`;
export const BMGFCodeInput = styled.input`
  height: 45px;
  width: 35px;
  outline: none;
  border-radius: 3px;
  border: 1px solid #c7c7c7;
  background: #f8f8f8;
  caret-color: #282828;
  text-align: center;
`;

export const BMGFCodeInputSubText = styled.p`
  color: #a2a3a6 !important;
  text-align: center;
`;

export const BMGFCodeValidateButtonContainer = styled.div`
  ${media.max.sm`
    position: fixed;
    bottom:0;
    padding: 15px;
    width:100%;
    border: 1px solid #E7E9EF;
  `}
`;

export const BMGFCodeValidateButton = styled.button`
  margin-top: 150px !important;
  margin-bottom: 10px !important;
  border-radius: 5px;
  border: none;
  background: #2a3390;
  color: #fff !important;
  padding: 13px 100px;

  font-size: 16px !important;
  font-weight: 700 !important;

  &:disabled {
    background: #c7c7c7;
  }

  ${media.max.sm`
    padding: 13px 50px;
    width:100%;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    font-size: 16px !important;
    font-weight: 700 !important;
  `}
`;
