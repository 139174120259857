import { FC } from "react";
import {
  BooleanInputButton,
  BooleanInputContainer,
  BooleanInputError,
  BooleanInputLabel,
  BooleanInputQuestionHeader,
  BooleanInputQuestionNumber,
} from "./BMGFBooleanInput.styles";

interface BMGFBooleanInputProps {
  questionNumber?: string;
  questionNumberToShow?: string;
  questionText?: string;
  value?: string;
  updateAnswers?: (data: any) => void;
  invalid?: boolean;
  options: any;
}

const BMGFBooleanInput: FC<BMGFBooleanInputProps> = ({
  questionNumber,
  questionNumberToShow,
  questionText,
  value,
  updateAnswers,
  invalid,
  options,
}) => {
  return (
    <BooleanInputContainer>
      <BooleanInputQuestionHeader>
        <BooleanInputQuestionNumber>
          {questionNumberToShow}.<BooleanInputLabel>{questionText}</BooleanInputLabel>
        </BooleanInputQuestionNumber>
      </BooleanInputQuestionHeader>

      <BooleanInputButton
        onClick={() => {
          updateAnswers({ [questionNumber]: options[0] });
        }}
        selected={value == options[0]}
      >
        {options[0]}
      </BooleanInputButton>
      <BooleanInputButton
        onClick={() => {
          updateAnswers({ [questionNumber]: options[1] });
        }}
        selected={value == options[1]}
      >
        {options[1]}
      </BooleanInputButton>

      {invalid && <BooleanInputError>Missing answer</BooleanInputError>}
    </BooleanInputContainer>
  );
};

export default BMGFBooleanInput;
