type Props = {
  fill?: string;
  styleProps?: object;
};

export default function ChevronDownIcon({ fill = "#282828", styleProps = {} }: Props) {
  return (
    <svg
      style={{ marginLeft: "7px", ...styleProps }}
      width="9"
      height="6"
      viewBox="0 0 9 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.000837326 1.45469L4.28655 5.5L8.57227 1.45469L7.56084 0.5L4.28655 3.6068L0.995123 0.5L0.000837326 1.45469Z"
        fill={fill}
      />
    </svg>
  );
}
