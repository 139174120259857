import { useQuery } from "@tanstack/react-query";
import useTranslation from "intl/useTranslation";
import { Mixpanel } from "lib/Mixpanel";
import Link from "next/link";
import { useRouter } from "next/router";
import { specPaths } from "public/assets/homePageData";
import { useContext, useState } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { useSelector } from "react-redux";
import { useWindowSize } from "react-use";
import { Col, Container, Row } from "styled-bootstrap-grid";
import { SliderViewAllArrow } from "TS_components/common/SilderViewAllArrow";
import CircleChevronIcon from "TS_components/HomeHero/icons/CircleChevronIcon";
import { SectionTitle } from "TS_components/HomePage/HomePage.styled";
import HomePageFilters from "TS_components/HomePageFilters";
import PopularDoctorCard from "TS_components/PopularDoctorCard";
import { _GetPopularDoctors } from "TS_Services/Doctors";
import { localize } from "utils/url";
import Skeleton from "./CardSkeleton";
import { ArrowBtn, CardsContainer, CarouselHeader, CarouselWrapper, Section } from "./PopularDoctorsSection.styles";

export function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } = useContext(VisibilityContext);

  return (
    <ArrowBtn className="section-left-arrow" isDisabled={isFirstItemVisible} onClick={() => scrollPrev()}>
      <CircleChevronIcon size={40} fill="#2A3390" bgColor="#F4F4F9" />
    </ArrowBtn>
  );
}

export function RightArrow() {
  const { isLastItemVisible, scrollNext } = useContext(VisibilityContext);
  return (
    <ArrowBtn className="section-right-arrow" isDisabled={isLastItemVisible} onClick={() => scrollNext()}>
      <CircleChevronIcon size={40} fill="#2A3390" bgColor="#F4F4F9" />
    </ArrowBtn>
  );
}

const PopularDoctorsSection = () => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const [doctorSlug, setDoctorSlug] = useState<string>(specPaths[0][`name_${locale}`]);
  const { client_side_country } = useSelector(({ InitialDataS }: any) => InitialDataS);
  const [activeFilter, setActiveFilter] = useState<string>("OBGYN");
  const { width } = useWindowSize();
  const isPhone = width < 768;
  const skeletonCount = isPhone ? 1 : 3;

  const pageFilters = specPaths.map((spec) => (spec.text === "Obstetricians & Gynecologists" ? "OBGYN" : spec.text));
  const { data } = useQuery(
    ["doctors", client_side_country, locale, doctorSlug],
    () =>
      _GetPopularDoctors({
        country: client_side_country,
        locale: locale,
        slug: doctorSlug,
        filters: { clinic: false, video: true, sortBy: "default", get_filters: true, featured: true },
      }),
    {
      staleTime: 1000 * 60, // Added a stale time here of 1 minute so it wont refetch every time the filter changes
    }
  );

  const viewAllClickEvent = () => {
    const eventName = "Homepage | View All Doctors | Arrow Button";
    const eventProps = {
      "Speciality Name": activeFilter,
      Language: locale.toUpperCase(),
      Locale: client_side_country || "qatar",
    };

    Mixpanel.track(eventName, eventProps);
  };

  const handleFilterChange = (filter) => {
    if (!filter) return;
    const eventName = "Homepage | Featured Doctor Speciality | Quick Filter";
    const speciality = filter === "OBGYN" ? specPaths[0].text : filter;
    const slug =
      filter === "OBGYN"
        ? specPaths[0][`name_${locale}`]
        : specPaths.find((spec) => spec.text === filter)[`name_${locale}`];

    const eventProps = {
      Language: locale.toUpperCase(),
      Locale: client_side_country || "qatar",
      "Speciality Name": speciality,
    };

    setActiveFilter(filter);
    setDoctorSlug(slug);
    Mixpanel.track(eventName, eventProps);
  };

  // todo: figure out the video issue.
  const listings = data?.listings;
  return (
    <Container>
      <Row>
        <Col col={12}>
          <Section>
            <SectionTitle>{t("Featured Doctors")}</SectionTitle>
            <HomePageFilters filters={pageFilters} callBack={handleFilterChange} activeFilter={activeFilter} />
            <CarouselWrapper>
              {listings ? (
                <ScrollMenu
                  RTL={locale === "ar"}
                  Header={() => (
                    <CarouselHeader>
                      <LeftArrow /> <RightArrow />
                    </CarouselHeader>
                  )}
                  scrollContainerClassName="scroll-container"
                  separatorClassName="scroll-separator"
                  itemClassName="scroll-item"
                >
                  {listings.slice(0, 4).map((doctor) => <PopularDoctorCard doctorData={doctor} />) as any}
                  {listings.length > 4 && (
                    <Link href={`/${localize(`doctors/${doctorSlug}`, client_side_country, locale)}`}>
                      <a
                        data-cy="View all buttons"
                        onClick={() => console.log("add mixpanel for view all here")}
                        className="view-all-btn"
                      >
                        <SliderViewAllArrow
                          onClick={viewAllClickEvent}
                          text={`${t("View All Doctors")}`}
                          direction={locale}
                        />
                      </a>
                    </Link>
                  )}
                </ScrollMenu>
              ) : (
                <CardsContainer>
                  {new Array(skeletonCount).fill(1).map(() => (
                    <Skeleton rtl={locale === "ar"} />
                  ))}
                </CardsContainer>
              )}
            </CarouselWrapper>
          </Section>
        </Col>
      </Row>
    </Container>
  );
};

export default PopularDoctorsSection;
