import { Mixpanel, getRoute } from "@/utils";
import dayjs from "dayjs";
import { useRouter } from "next/router";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Col, Container, Row } from "styled-bootstrap-grid";
import { Steps } from "./BMGF.parts";
import {
  BMGFBackButton,
  BMGFEndButton,
  BMGFFooterButtonContainer,
  BMGFFooterContainer,
  BMGFFooterGroup,
  BMGFFooterStepper,
  BMGFFooterStepperContainer,
  BMGFHeader,
  BMGFHeading,
  BMGFNextButton,
  BMGFSubHeading,
  QuestionContainer,
} from "./BMGF.styles";
import { checkHideConditions, skipWholeQuestion } from "./BMGFHelper";
import BMGFStepper from "./BMGFStepper";

interface BMGFProps {
  setParentNumber?: (number) => void;
  setIsFormSubmitted?: (flag) => void;
  setResponse?: (response) => void;
  redirectToResults?: () => void;
  questionsData?: any;
  questionCategories?: Array<string>;
}
const BMGF: FC<BMGFProps> = ({
  setParentNumber,
  setIsFormSubmitted,
  setResponse,
  redirectToResults,
  questionCategories,
  questionsData,
}) => {
  const router = useRouter();
  const [step, setStep] = useState(1);
  const [answers, setAnswers] = useState({});
  const [questions, setQuestions] = useState([]);
  const [number, setNumber] = useState("");
  const [isValidNumber, setIsValidNumber] = useState(false);
  const [showNumberError, setShowNumberError] = useState(false);
  const [maxStep, setMaxStep] = useState(questionCategories.length);
  const { client_side_country } = useSelector(({ InitialDataS }) => InitialDataS);
  const [patiendInfoValidation, setPatientInfoValidation] = useState({
    patient_name: { invalid: false },
    patient_state: { invalid: false },
  });

  const [mappedAnswers, setMappedAnswers] = useState({});

  const isMobile = useMediaQuery({
    maxWidth: 767,
  });
  const updateAnswers = (data, stepToUpdate = null) => {
    let currentStep = "Step" + (stepToUpdate ? stepToUpdate : step);
    let currenStepAnswers = answers[currentStep];
    let updatedAnswers = { ...answers };
    updatedAnswers[currentStep] = { ...currenStepAnswers, ...data };
    setAnswers(updatedAnswers);
    let currentMappedAnswers = {};
    Object.keys(updatedAnswers).forEach((key) => {
      currentMappedAnswers = { ...currentMappedAnswers, ...updatedAnswers[key] };
    });

    setMappedAnswers(currentMappedAnswers);
    sessionStorage.setItem("bmgfanswers", JSON.stringify(updatedAnswers));
  };

  const { initData } = useSelector(({ InitialData }) => InitialData);

  useEffect(() => {
    if (initData) {
      Mixpanel.track(`BMGF Questionnaire | Page ${step} Loaded | Event`, {
        "is Logged In": initData?.user_is_authenticated,
      });
    }
  }, [step, initData]);
  useEffect(() => {}, [router.query]);
  useEffect(() => {
    let storedAnswers = sessionStorage.getItem("bmgfanswers");
    let parsedAnswers = JSON.parse(storedAnswers) || {};

    setAnswers(parsedAnswers);
    let currentMappedAnswers = {};
    Object.keys(parsedAnswers).forEach((key) => {
      currentMappedAnswers = { ...currentMappedAnswers, ...parsedAnswers[key] };
    });

    setMappedAnswers(currentMappedAnswers);

    // MAP QUESTIONS ARRAY FOR INVALID QUESTION VALIDATIONS
    // let tempQuestions = StepQuestionsObject;
    // let invalidationsObject = {};
    // let tempAnswers = {};
    // Object.keys(tempQuestions).forEach((step) => {
    //   let stepQuestions = {};
    //   tempAnswers[step] = {};
    //   Object.keys(tempQuestions[step]).forEach((question) => {
    //     // SET INITIAL INVALID OPTION TO FALSE
    //     stepQuestions[question] = { invalid: false };
    //     tempAnswers[step][question] = JSON.parse(storedAnswers)?.[step]?.[question] || '';
    //   });
    //   invalidationsObject[step] = stepQuestions;
    // });

    // setAnswers(tempAnswers);
    // setQuestions(questionsData);
  }, []);

  useEffect(() => {
    setQuestions(questionsData);
    setMaxStep(questionCategories.length);

    if (questionCategories.length) {
      let praUserData = JSON.parse(sessionStorage.getItem("praUserData"));
      let patient_info = {
        patient_name: praUserData.firstName + " " + praUserData.lastName,
        patient_state: {
          city: praUserData.state,
          area: praUserData.lga,
        },
      };

      updateAnswers(patient_info, questionCategories.length);
      setNumber(praUserData.phoneNumber);
    }
    let currentSlug = parseInt(router.query.slug);
    if (currentSlug && currentSlug >= 1 && currentSlug <= questionCategories.length) {
      setStep(currentSlug);
    }
  }, [questionsData]);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [step]);
  const nextStep = () => {
    if (step == maxStep) {
      return;
    }

    Mixpanel.track("BMGF Questionnaire | Next Clicked | Button", {
      "Page Number": step,
      "is Logged In": initData?.user_is_authenticated,
    });

    // DONT REMOVE THIS
    let isSomeInvalid = inputValidations();
    if (isSomeInvalid) {
      Mixpanel.track("BMGF Questionnaire | Missing Answers | Event", {
        "Page Number": step,
      });

      return;
    }
    setStep(step + 1);
    window.history.pushState({}, "", getRoute(client_side_country, `/pregnancy-risk-assessment/`) + (step + 1));
  };
  const backStep = () => {
    if (step == 1) {
      return;
    }
    setStep(step - 1);
    window.history.pushState({}, "", getRoute(client_side_country, `/pregnancy-risk-assessment/`) + (step - 1));
  };

  const mapAnswers = () => {
    let response = [];
    // let mappedAnswers = {};
    // Object.keys(answers).forEach((key) => {
    //   mappedAnswers = { ...mappedAnswers, ...answers[key] };
    // });

    questions.forEach((question) => {
      let currentQuestion: any = {};
      currentQuestion.id = question.id;
      let currentAnswers = [];

      if (
        question.hideConditions &&
        (skipWholeQuestion(question, mappedAnswers) || checkHideConditions(question, mappedAnswers))
      ) {
        return;
      }

      question.questions.forEach((que) => {
        if (skipWholeQuestion(question, mappedAnswers, que) || checkHideConditions(question, mappedAnswers, que)) {
          return;
        }
        let currQuestion: any = {};
        if (mappedAnswers[currentQuestion.id + que.number]) {
          let mappedAnswer = mappedAnswers[currentQuestion.id + que.number];
          if (que.inputType == "multi") {
            mappedAnswer = mappedAnswer.map((ans) => {
              return ans.text;
            });
          }
          if (que.inputType == "number") {
            mappedAnswer = parseInt(mappedAnswer);
          }

          if (que.inputType == "date") {
            mappedAnswer = dayjs(mappedAnswer).format("YYYY-MM-DD");
          }
          currQuestion.number = que.number;
          currQuestion.response = mappedAnswer;
          currentAnswers.push(currQuestion);
        }
      });
      currentQuestion.questions = currentAnswers;
      if (currentAnswers.length) {
        response.push(currentQuestion);
      }
    });

    let finalResponse = {
      response,
      name: answers["Step" + maxStep].patient_name,
      state: answers["Step" + maxStep]?.patient_state.city,
      lga: answers["Step" + maxStep]?.patient_state.area,
    };

    return finalResponse;
  };
  const inputValidations = () => {
    let isInputMissing = false;

    let stepMarker = "Step" + step;
    let tempQuestions = questions;
    let isSomeInvalid = false;
    let currentCategory = questionCategories[step - 1];
    let currentQuestions = questions.slice(0);
    currentQuestions.map((questionData) => {
      let skipChild = false;
      if (currentCategory != questionData.category) {
        return;
      }

      if (skipWholeQuestion(questionData, mappedAnswers) || checkHideConditions(questionData, mappedAnswers)) {
        return;
      }
      return questionData.questions.map((question) => {
        if (
          skipWholeQuestion(questionData, mappedAnswers, question) ||
          checkHideConditions(questionData, mappedAnswers, question)
        ) {
          return;
        }
        let questionNumber = questionData.id + question.number;
        if (!answers[stepMarker]?.[questionNumber] || !answers[stepMarker]?.[questionNumber]?.length) {
          question.invalid = true;
          isSomeInvalid = true;
        } else {
          question.invalid = false;
        }

        // skipChild = shallHideChild(question, answers[stepMarker]?.[questionNumber]);

        // if (question.hasChild && question.displayChildTruthOption.includes()) {
        //   skipChild = false;
        // } else {
        //   skipChild = true;
        // }
      });
    });

    let checkPatientInfo = { ...patiendInfoValidation };
    if (stepMarker == "Step" + maxStep && !answers[stepMarker]?.["patient_name"]) {
      checkPatientInfo["patient_name"].invalid = true;
      isSomeInvalid = true;
    } else {
      checkPatientInfo["patient_name"].invalid = false;
    }
    if (stepMarker == "Step" + maxStep && !answers[stepMarker]?.["patient_state"]) {
      checkPatientInfo["patient_state"].invalid = true;
      isSomeInvalid = true;
    } else {
      checkPatientInfo["patient_state"].invalid = false;
    }

    setQuestions(currentQuestions);
    setPatientInfoValidation(checkPatientInfo);

    // Object.keys(tempQuestions[stepMarker]).forEach((question) => {
    //   if (
    //     !answers[stepMarker][question] &&
    //     ((StepQuestionsObject[stepMarker][question].shouldShow &&
    //       StepQuestionsObject[stepMarker][question].shouldShow(answers, stepMarker) == true) ||
    //       !StepQuestionsObject[stepMarker][question].shouldShow)
    //   ) {
    //     tempQuestions[stepMarker][question].invalid = true;
    //     isSomeInvalid = true;
    //   } else {
    //     tempQuestions[stepMarker][question].invalid = false;
    //   }
    // });

    // setQuestions(questionsData);
    return isSomeInvalid;
  };
  return (
    <>
      {isMobile && step > 1 && (
        <BMGFStepper questionCategories={questionCategories} backStep={backStep} step={step}></BMGFStepper>
      )}
      <Container style={{ marginBottom: "70px" }}>
        <Row
          style={{
            width: `${isMobile ? "100%" : "auto"}`,
            marginRight: `${isMobile ? "0px" : "auto"}`,
            marginLeft: `${isMobile ? "0px" : "auto"}`,
          }}
        >
          <Col xs={12} col={6} className={`${isMobile ? "px-0" : ""}`}>
            {step == 1 && (
              <BMGFHeader>
                <BMGFHeading>Pregnancy Risk Assessment</BMGFHeading>
                <BMGFSubHeading>
                  This questionnaire is to assess your pregnancy risk level and recommend appropriate actions.
                </BMGFSubHeading>
              </BMGFHeader>
            )}
            <QuestionContainer>
              {questions && (
                <Steps
                  step={step}
                  maxStep={maxStep}
                  answers={answers}
                  mappedAnswers={mappedAnswers}
                  questionsData={questions}
                  questionsCategories={questionCategories}
                  updateAnswers={updateAnswers}
                  showPhoneInput={step == maxStep && !initData?.user_is_authenticated}
                  patiendInfoValidation={patiendInfoValidation}
                  setIsValidNumber={(isValid) => {
                    setIsValidNumber(isValid);
                  }}
                  showNumberError={showNumberError}
                  setBookingNumber={(number) => {
                    setNumber(number);
                  }}
                  phoneNumber={number}
                ></Steps>
              )}
              {/* {step == 2 && <Step2 answers={answers} updateAnswers={updateAnswers}></Step2>}
              {step == 3 && <Step3 answers={answers} updateAnswers={updateAnswers}></Step3>}

              {step == 4 && (
                <Step4
                  answers={answers}
                  updateAnswers={updateAnswers}
                  setBookingNumber={(number) => {
                    setNumber(number);
                  }}
                  showPhoneInput={!initData?.user_is_authenticated}
                ></Step4>
              )} */}
            </QuestionContainer>
          </Col>
          {!isMobile && <BMGFStepper questionCategories={questionCategories} step={step}></BMGFStepper>}
        </Row>
      </Container>
      <BMGFFooterContainer>
        <BMGFFooterGroup isMobileSubmit={step == maxStep && isMobile} isMobile={isMobile}>
          {((isMobile && step < maxStep) || !isMobile) && (
            <BMGFFooterStepperContainer>
              {questionCategories.map((category, i) => {
                return <BMGFFooterStepper previous={step > i + 1} selected={step > i}></BMGFFooterStepper>;
              })}

              {/* <BMGFFooterStepper previous={step > 1} selected={step > 0}></BMGFFooterStepper>
              <BMGFFooterStepper previous={step > 2} selected={step > 1}></BMGFFooterStepper>
              <BMGFFooterStepper previous={step > 3} selected={step > 2}></BMGFFooterStepper>
              <BMGFFooterStepper selected={step > 3}></BMGFFooterStepper> */}
            </BMGFFooterStepperContainer>
          )}
          <BMGFFooterButtonContainer>
            {step > 1 && step < maxStep && isMobile && <BMGFBackButton onClick={() => backStep()}>Back</BMGFBackButton>}
            {step > 1 && !isMobile && <BMGFBackButton onClick={() => backStep()}>Back</BMGFBackButton>}

            {step < maxStep && <BMGFNextButton onClick={() => nextStep()}>Next</BMGFNextButton>}
            {step == maxStep && (
              <BMGFEndButton
                onClick={async () => {
                  Mixpanel.track("BMGF Questionnaire | Submit | Button", {
                    "is Logged In": initData?.user_is_authenticated,
                  });

                  let isSomeInvalid = inputValidations();
                  if (isSomeInvalid) {
                    Mixpanel.track("BMGF Questionnaire | Missing Answers | Event", {
                      "Page Number": step,
                    });
                    return;
                  }

                  // if (initData?.user_is_authenticated) {
                  //   // redirectToResults();
                  //   // sessionStorage.removeItem("bmgfanswers");
                  // } else {

                  // UNCOMMENT THIS ONCE INVALID NUMBER ISSUE IS RESOLVED
                  // if (!isValidNumber && !initData.user_is_authenticated) {
                  //   Mixpanel.track("BMGF Questionnaire | Mobile Number Invalid | Event", {
                  //     Flow: "questionnaire",
                  //   });
                  //   setShowNumberError(true);
                  //   return;
                  // }

                  let mappedAnswers = mapAnswers();
                  setResponse(mappedAnswers);

                  setShowNumberError(false);
                  setParentNumber(number);
                  //   setIsFormSubmitted(true);
                  // }
                  // sessionStorage.removeItem('bmgfanswers');
                  // window.location.href = '/ng/bmgf/result';
                }}
              >
                Submit & Get Instant Results
              </BMGFEndButton>
            )}
          </BMGFFooterButtonContainer>
        </BMGFFooterGroup>
      </BMGFFooterContainer>
    </>
  );
};

export default BMGF;
