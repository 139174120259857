import styled from 'styled-components';

export const HomeWrapper = styled.main`
  background: ${({ theme }) => theme.colors.bgColor};
`;
export const SectionTitle = styled.h2`
  font-size: 2rem !important;
  font-weight: ${({ theme }) => theme.fontWeight.font700} !important;
  color: ${({ theme }) => theme.colors.textDark};
  line-height: 1.25 !important;
  text-align: left;
  margin: 0 0 15px 0 !important;
`;
