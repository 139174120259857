import useTranslation from "@/intl/useTranslation";
import {
  autoUpdate,
  ContextData,
  flip,
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  offset,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
} from "@floating-ui/react-dom-interactions";
import debounce from "lodash/debounce";
import React, {
  createContext,
  Dispatch,
  memo,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useIsomorphicLayoutEffect } from "react-use";
import { Area, City } from "types/Home";
import {
  DoctorOption,
  DoctorOptionCount,
  DoctorOptionText,
  DropdownWrapper,
  StyledOption,
  StyledUl,
} from "../CommonDropdown/CommonDropdown.styled";
import SearchInput from "../CommonDropdown/SearchInput";
import CityAreaTabs from "./CityAreaTabs";

interface SelectContext {
  activeIndex: number | null;
  setActiveIndex: (index: number | null) => void;
  value?: CountryValue;
  setValue: Dispatch<SetStateAction<CountryValue>>;
  listRef: React.MutableRefObject<Array<HTMLLIElement | null>>;
  setOpen: (open: boolean) => void;
  getItemProps: (userProps?: React.HTMLProps<HTMLElement>) => any;
  dataRef: ContextData;
  afterSelectCallback: (type: string, optionValue: string) => void;
}
const TheSelectContext = createContext({} as SelectContext);

export interface CountryValue {
  city?: string;
  area?: string;
}

interface Props {
  areas: Area[];
  cities?: City[];
  value?: CountryValue;
  setValue: Dispatch<SetStateAction<CountryValue>>;
  placeholder: string;
  onToggleDropdown?: (isOpened: boolean, type: "City" | "Area") => void;
  onSelectOption?: (val: CountryValue, type: "City" | "Area") => void;
  onSearchInput?: (searchTerm: string, type: "City" | "Area") => void;
  startSearchAnalytics?: (searchTerm: string, type: "City" | "Area") => void;
  position?: string;
  triggerer: any;
  left?: number;
  hideAllKeywordOption?: boolean;
}

export const TheOption: React.FC<{
  optionType: string;
  optionLabel: string;
  optionValue: string;
  doctorCount?: number;
  index: number;
}> = memo(({ index = 0, optionLabel, optionType, optionValue, doctorCount }) => {
  const { listRef, setActiveIndex, afterSelectCallback, value, setValue, activeIndex, getItemProps, dataRef } =
    useContext(TheSelectContext);

  const handleSelect = () => {
    setValue({
      ...value,
      [optionType]: optionValue,
    });
    setActiveIndex(null);
    afterSelectCallback(optionType, optionValue);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSelect();
    }

    if (event.key === " ") {
      event.preventDefault();
    }
  };

  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === " " && !dataRef.current.typing) {
      handleSelect();
    }
  };

  return (
    <StyledOption
      role="option"
      ref={(node) => (listRef.current[index] = node)}
      tabIndex={activeIndex === index ? 0 : 1}
      aria-selected={activeIndex === index}
      data-selected={value[optionType] === optionValue}
      isSelected={value[optionType] === optionValue}
      data-cy={optionType + "-" + (optionValue || "")}
      {...getItemProps({
        onClick: handleSelect,
        onKeyDown: handleKeyDown,
        onKeyUp: handleKeyUp,
      })}
      isDisabled={doctorCount != undefined && doctorCount == 0}
    >
      {doctorCount != undefined && (
        <DoctorOption>
          <DoctorOptionText>{optionLabel}</DoctorOptionText>
          <DoctorOptionCount isDisabled={doctorCount == 0}>{doctorCount} Doctors</DoctorOptionCount>
        </DoctorOption>
      )}
      {doctorCount == null && optionLabel}
    </StyledOption>
  );
});

export default function CountriesDropdown({
  areas,
  cities,
  value: originalValue,
  setValue: setOriginalValue,
  placeholder,
  onToggleDropdown,
  onSelectOption,
  onSearchInput,
  startSearchAnalytics,
  triggerer,
  left,
  hideAllKeywordOption = false,
}: Props) {
  const { locale, t } = useTranslation();
  const isDesktop = useMediaQuery({
    minWidth: 768,
  });
  const { client_side_country } = useSelector(({ InitialDataS }: any) => InitialDataS);
  const isNigeriaLocale = client_side_country === "ng";
  const isTwoSteps = Boolean(cities);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [value, setValue] = useState({
    area: "",
    city: "",
    ...(originalValue || {}),
  });

  const data = useMemo(() => {
    let areaOptions = [...(areas || [])];
    let cityOptions = [...(cities || [])];

    if (!hideAllKeywordOption) {
      let allAreas: any = {};
      let allCities: any = {};
      allAreas = { name: t("All Areas"), name_en: "All Areas", value: "All Areas" };
      allCities = {
        name: isNigeriaLocale ? "All States" : t("All Cities"),
        name_en: isNigeriaLocale ? "All States" : "All Cities",
        value: "All Cities",
      };
      areaOptions.unshift(allAreas);
      cityOptions.unshift(allCities);
    }

    return [
      {
        label: "area",
        options: areaOptions,
      },
      {
        label: "city",
        options: cityOptions,
      },
    ];
  }, []);

  const listItemsRef = useRef<Array<HTMLLIElement | null>>([]);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [open, setOpen] = useState(false);
  const [pointer, setPointer] = useState(false);
  const [selectedTab, setSelectedTab] = useState<"area" | "city">(isTwoSteps ? (value.area ? "area" : "city") : "area");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const searchInputRef = useRef(null);

  if (!open && pointer) {
    setPointer(false);
    setSearchTerm("");
  }

  const [customListHeight, setCustomListHeight] = useState(null);
  const { x, y, reference, floating, strategy, context } = useFloating({
    open,
    onOpenChange: () => {
      onToggleDropdown(!open, isTwoSteps ? (selectedTab === "city" ? "City" : "Area") : "Area");
      setOpen((prev) => !prev);
    },
    whileElementsMounted: autoUpdate,
    placement: "bottom-start",
    middleware: [
      offset(5),
      shift(),
      flip(),
      size({
        apply({ availableHeight, elements }) {
          if (availableHeight <= 400) {
            setCustomListHeight(availableHeight - 10);
          } else {
            setCustomListHeight(null);
          }
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
          });
        },
      }),
    ],
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    useClick(context),
    useRole(context, { role: "listbox" }),
    useDismiss(context),
    useListNavigation(context, {
      listRef: listItemsRef,
      activeIndex,
      onNavigate: setActiveIndex,
      nested: isTwoSteps,
    }),
  ]);

  const selectedTabData = useMemo(() => {
    let theData = data.find((dataType) => dataType.label === selectedTab);
    let theSearchTerm = searchTerm.toLocaleLowerCase();

    if (isTwoSteps) {
      if (selectedTab === "city") {
        if (searchTerm) {
          let options = theData.options.filter(
            (op) =>
              op.name_en.toLocaleLowerCase().includes(theSearchTerm) ||
              (op.name !== null && op.name.toLowerCase().includes(theSearchTerm))
          );
          return {
            ...theData,
            options: options.length ? options : theData.options,
          };
        } else {
          return theData;
        }
      } else {
        let areasInCity = cities.find((city) => city.value === value.city)?.areas || [];

        if (searchTerm) {
          let options = areasInCity.filter(
            (op) =>
              op.name_en.toLocaleLowerCase().includes(theSearchTerm) ||
              (op.name !== null && op.name.toLowerCase().includes(theSearchTerm))
          );
          return {
            ...theData,
            options: options.length ? options : [...theData.options, ...areasInCity],
          };
        } else {
          return {
            ...theData,
            options: [...theData.options, ...areasInCity],
          };
        }
      }
    } else {
      if (searchTerm) {
        let options = theData.options.filter(
          (op) =>
            op.name_en.toLocaleLowerCase().includes(theSearchTerm) ||
            (op.name !== null && op.name.toLowerCase().includes(theSearchTerm))
        );
        return {
          ...theData,
          options: options.length ? options : theData.options,
        };
      } else {
        return theData;
      }
    }
  }, [selectedTab, searchTerm, value]);

  // Scroll the active or selected item into view when in `controlledScrolling`
  // mode (i.e. arrow key nav).
  useIsomorphicLayoutEffect(() => {
    if (open && activeIndex != null && !pointer) {
      requestAnimationFrame(() => {
        listItemsRef.current[activeIndex]?.scrollIntoView({
          block: "nearest",
        });
      });
    }
  }, [open, activeIndex, pointer]);

  useEffect(() => {
    if (open && searchInputRef?.current && isDesktop) {
      setTimeout(() => {
        searchInputRef?.current?.focus();
      }, 300);
    }
  }, [open, selectedTab]);

  const afterSelectCallback = (type: "city" | "area", optionValue) => {
    if (isTwoSteps) {
      setSearchTerm("");
      if (type === "city") {
        onSelectOption(
          {
            city: optionValue,
            area: "",
          },
          "City"
        );
        setValue((prev) => ({
          ...prev,
          area: "",
        }));
        if (optionValue === "All Cities") {
          setOpen(false);
        } else {
          setSelectedTab("area");
          onToggleDropdown(true, "Area");
        }
      } else {
        setOpen(false);
        onSelectOption(
          {
            city: value.city,
            area: optionValue,
          },
          "Area"
        );
      }
    } else {
      onSelectOption(
        {
          area: optionValue,
          city: null,
        },
        "Area"
      );
      setOpen(false);
    }
  };

  const handleOnClickTabs = (type: "city" | "area") => {
    if (type === "city") {
      setSelectedTab(type);
      setSearchTerm("");
      onToggleDropdown(true, "City");
    }
  };

  const getLabelName = () => {
    if (isTwoSteps) {
      let cityName = value?.city
        ? value.city === "All Cities"
          ? null
          : cities.find((city) => city.value === value.city).name
        : null;
      let areaName =
        value?.city && value?.area
          ? value.area === "All Areas"
            ? null
            : cities.find((city) => city.value === value.city).areas.find((area) => area.value === value.area).name
          : null;

      return cityName ? (areaName ? cityName + " - " + areaName : cityName) : placeholder;
    } else {
      if (value?.area) {
        return value.area === "All Areas" ? placeholder : areas.find((area) => area.value === value.area).name;
      } else {
        return placeholder;
      }
    }
  };

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }

    setOriginalValue(value);
  }, [value]);

  const _debouncedOnSearchInput = useMemo(() => (onSearchInput ? debounce(onSearchInput, 500) : null), []);

  return (
    <TheSelectContext.Provider
      value={{
        activeIndex,
        setActiveIndex,
        value: {
          city: "",
          area: "",
          ...value,
        },
        afterSelectCallback,
        setValue,
        listRef: listItemsRef,
        setOpen,
        getItemProps,
        dataRef: context.dataRef,
      }}
    >
      {triggerer({
        labelName: getLabelName(),
        "data-cy": "country-trigger",
        ...getReferenceProps({
          ref: reference,
          style: open
            ? {
                zIndex: 11,
              }
            : {},
        }),
      })}
      {open && (
        <FloatingPortal>
          <FloatingOverlay
            lockScroll
            style={{
              background: "rgba(0, 0, 0, 0.4)",
              zIndex: 10,
            }}
          >
            <FloatingFocusManager
              context={context}
              initialFocus={(() => {
                return (
                  data
                    .find((dataType) => dataType.label === selectedTab)
                    .options.findIndex((op) => op.value === value?.[selectedTab]) + 1
                );
              })()}
            >
              <DropdownWrapper
                dir={locale === "ar" ? "rtl" : "ltr"}
                data-cy="country-dropdown"
                customListHeight={customListHeight}
                {...getFloatingProps({
                  ref: floating,
                  style: {
                    position: strategy,
                    top: y ?? 0,
                    left: left ? left : x ?? 0,
                  },
                  onPointerMove() {
                    setPointer(true);
                  },
                  onKeyDown(event) {
                    setPointer(false);
                    if (event.key === "Tab") {
                      setOpen(false);
                    }
                  },
                })}
              >
                {isTwoSteps && <CityAreaTabs selectedTab={selectedTab} handleOnClickTabs={handleOnClickTabs} />}
                <SearchInput
                  label={`${selectedTab}-dropdown-search-input`}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  placeholder={
                    selectedTab === "area" ? t("Search Areas") : isNigeriaLocale ? "Search States" : t("Search Cities")
                  }
                  ref={searchInputRef}
                  onSearchCallback={(searchTerm) => {
                    if (onSearchInput) _debouncedOnSearchInput(searchTerm, selectedTab === "area" ? "Area" : "City");
                  }}
                  startSearchAnalytics={(searchTerm) => {
                    if (startSearchAnalytics)
                      startSearchAnalytics(searchTerm, selectedTab === "area" ? "Area" : "City");
                  }}
                />
                <StyledUl
                  key={selectedTabData.label}
                  role="group"
                  aria-labelledby={`floating-ui-select-${selectedTabData.label}`}
                  data-cy="country-dropdown-list"
                >
                  {selectedTabData.options.map((option, index) => {
                    return (
                      <TheOption
                        key={option.value}
                        index={index}
                        optionType={selectedTabData.label}
                        optionLabel={option.name}
                        optionValue={option.value}
                        doctorCount={option?.number_of_doctors}
                      />
                    );
                  })}
                </StyledUl>
              </DropdownWrapper>
            </FloatingFocusManager>
          </FloatingOverlay>
        </FloatingPortal>
      )}
    </TheSelectContext.Provider>
  );
}
