import { FC } from "react";

import {
  RadioInputButton,
  RadioInputButtonCheck,
  RadioInputButtonDefault,
  RadioInputButtonGroup,
  RadioInputButtonLabel,
  RadioInputContainer,
  RadioInputError,
  RadioInputLabel,
  RadioInputQuestionHeader,
  RadioInputQuestionNumber,
} from "./BMGFRadioInput.styles";

interface BMGFRadioInputProps {
  questionNumber?: string;
  questionNumberToShow?: string;
  questionText?: string;
  value?: string;
  updateAnswers?: (data: any) => void;
  type: string;
  items: Array<string>;
  invalid?: boolean;
  options: any;
}

const BMGFRadioInput: FC<BMGFRadioInputProps> = ({
  questionNumber,
  questionNumberToShow,
  questionText,
  value,
  updateAnswers,
  type,
  items,
  invalid,
  options,
}) => {
  return (
    <RadioInputContainer>
      <RadioInputQuestionHeader>
        <RadioInputQuestionNumber>
          {questionNumberToShow}.<RadioInputLabel>{questionText}</RadioInputLabel>
        </RadioInputQuestionNumber>
      </RadioInputQuestionHeader>
      <RadioInputButtonGroup>
        {options.map((item, i) => {
          return (
            <RadioInputButtonDefault className="bmgf-radio-button-default">
              <RadioInputButton
                id={questionNumber + "-" + i}
                name={`radio-${questionNumber}`}
                type={type}
                onClick={() => {
                  updateAnswers({ [questionNumber]: item });
                }}
              ></RadioInputButton>
              <RadioInputButtonLabel htmlFor={questionNumber + "-" + i}>{item}</RadioInputButtonLabel>
              <RadioInputButtonCheck checked={value == item}></RadioInputButtonCheck>
            </RadioInputButtonDefault>
          );
        })}
      </RadioInputButtonGroup>
      {invalid && <RadioInputError>Missing answer</RadioInputError>}
    </RadioInputContainer>
  );
};

export default BMGFRadioInput;
