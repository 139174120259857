import Axios from "lib/Axios";
import { PregnancyRiskAssessment } from "types/PregnancyRiskAssessment";

const ARS_HEADERS = {
  "x-public-key": process.env.NEXT_PUBLIC_ARS_PUBLIC_KEY,
};

export const _GetPregnancyRiskAssessmentQuestions = () =>
  Axios.get<PregnancyRiskAssessment>(`${process.env.NEXT_PUBLIC_ARS_BASE_URL}questions`, {
    params: {
      platform: "heliumDoc",
    },
    headers: ARS_HEADERS,
  }).then((res) => res.data);

export const _PostPregnancyRiskAssessmentResponse = (obj) =>
  Axios.post("/ars/stratify-risks", obj).then(({ data }) => data);

export const _GetResultFromResultId = (id) =>
  Axios.get<PregnancyRiskAssessment>("/ars/result/" + id).then((res) => res.data);

export const _GetResultsFromUser = () => Axios.get<PregnancyRiskAssessment>(`/ars/results`).then((res) => res.data);

export const _GetBMGFCode = (code) =>
  Axios.post(
    `${process.env.NEXT_PUBLIC_ARS_BASE_URL}participant/${code}/verify-code`,
    {},
    {
      headers: ARS_HEADERS,
    }
  ).then((res) => res.data);

export const _GetLGAByState = (id) =>
  Axios.get(`${process.env.NEXT_PUBLIC_ARS_BASE_URL}getLgasByState/${id}`, {
    headers: ARS_HEADERS,
  }).then((res) => res.data);
