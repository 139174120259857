import ContentLoader from 'react-content-loader';

const MyLoader = (props) => (
  <ContentLoader
    speed={2}
    width={320}
    height={350}
    viewBox="0 0 320 350"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="16" y="11" rx="0" ry="0" width="72" height="21" />
    <rect x="100" y="11" rx="0" ry="0" width="72" height="21" />
    <circle cx="48" cy="81" r="38" />
    <rect x="102" y="49" rx="0" ry="0" width="81" height="12" />
    <rect x="101" y="72" rx="0" ry="0" width="201" height="13" />
    <rect x="101" y="94" rx="0" ry="0" width="151" height="12" />
    <rect x="13" y="136" rx="0" ry="0" width="296" height="18" />
    <rect x="25" y="266" rx="0" ry="0" width="110" height="41" />
    <rect x="12" y="165" rx="0" ry="0" width="298" height="18" />
    <rect x="12" y="192" rx="0" ry="0" width="296" height="18" />
    <rect x="147" y="265" rx="0" ry="0" width="162" height="42" />
    <rect x="61" y="323" rx="0" ry="0" width="201" height="13" />
  </ContentLoader>
);

export default MyLoader;
