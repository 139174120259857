import styled from "styled-components";

export const CheckboxInputContainer = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;
export const CheckboxInputQuestionHeader = styled.div`
  display: flex;
`;
export const CheckboxInputQuestionNumber = styled.p`
  font-size: 16px !important;
`;
export const CheckboxInputLabel = styled.p`
  font-size: 16px !important;
  font-weight: bold !important;
  margin-left: 5px !important;
`;

export const CheckboxInputParent = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckboxInputButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 10px 0;
  border-bottom: 1px solid $athens-gray;
`;

export const CheckboxInputButtonDefault = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0.5rem 0;
`;

export const CheckboxInputButton = styled.input<{ checked?: boolean }>`
  border: 0.85px solid #2a3390;
  transform: scale(0.654);
  background: ${({ checked }) => (checked ? "#2A3390 !important" : "")};

  margin: 0px !important;
  margin-left: -5px !important;
  &::before {
    color: ${({ checked }) => (checked ? "#fff" : "")};
  }
`;

export const CheckboxInputButtonLabel = styled.label`
  padding: 0 27px;
  z-index: 9;
  font-weight: 400 !important;
  font-size: 14px !important;
  text-align: left;
  font-weight: 500;
  color: #282828;
  position: relative;
  cursor: pointer;
  top:3px;
  
}`;

export const CheckboxInputButtonCheck = styled.div<{ checked?: boolean }>`
  display: block;
  position: absolute;
  border: 1px solid #ccd2dd;
  border-radius: 100%;
  height: 17px;
  width: 17px;
  // top: 5px;
  // left: 0;
  z-index: 5;
  transition: 0.15s ease all;
  border: ${({ checked }) => (checked ? "0.85px solid #2A3390" : "0.85px solid #C7C7C7")};

  &::before {
    display: block;
    position: absolute;
    content: "";
    border-radius: 100%;
    height: 8.5px;
    width: 8.5px;
    top: 25%;
    left: 25%;
    background: ${({ checked }) => (checked ? "#2A3390" : "transparent")};
  }
`;
