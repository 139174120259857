import CalendarIconBlack from "public/next-images/calendar.svg";
import styled from "styled-components";

export const CalendarIcon = styled(CalendarIconBlack)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  left: 10px;
  pointer-events: none;
`;
