import dayjs from "dayjs";
export const skipWholeQuestion = (question: any, answers: any, part?: any): boolean => {
  let skipWholeQuestion = false;
  let currentHideConditions = false;
  if (part) {
    currentHideConditions = part.hideConditions;
  } else {
    currentHideConditions = question.hideConditions;
  }

  // if (question.id == 20 && part && part.number == "b" && answers["20a"]) {
  //   if (answers["20a"].map((ans) => ans.text).includes("Fever")) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }

  if (question.id == 22 && part && part.number == "b" && answers["22a"]) {
    if (answers["22a"].map((ans) => ans.text).includes("HIV")) {
      return false;
    } else {
      return true;
    }
  }
  if (question.id == 22 && part && part.number == "c" && answers["22a"] && answers["22b"]) {
    if (answers["22a"].map((ans) => ans.text).includes("HIV") && answers["22b"] == "Yes") {
      return false;
    } else {
      return true;
    }
  }

  currentHideConditions &&
    Object.keys(currentHideConditions).forEach((key) => {
      let splittedKey = key.split("_").join("");
      let answerToCheck = answers[splittedKey];
      if (!answerToCheck && question.id != 16 && question.id != 17) {
        skipWholeQuestion = true;
        return;
      }
      if (
        Array.isArray(answerToCheck) &&
        answerToCheck.some((answer) => {
          if (answer.text) {
            return currentHideConditions[key].includes(answer.text);
          } else {
            return currentHideConditions[key].includes(answer);
          }
        })
      ) {
        skipWholeQuestion = true;
      } else if (question.id == 16 || question.id == 17) {
        skipWholeQuestion = checkLMDCondition(currentHideConditions, answers);
      } else if (
        answerToCheck &&
        (currentHideConditions[key].includes(answerToCheck) ||
          currentHideConditions[key].includes(parseInt(answerToCheck)))
      ) {
        skipWholeQuestion = true;
      }
    });

  return skipWholeQuestion;
};

export const shallHideChild = (question: any, answer: any): boolean => {
  if (
    (question.hasChild && question.displayChildTruthOption.includes(answer)) ||
    (question.hasChild && question.displayChildTruthOption.includes(parseInt(answer))) ||
    (question.hasChild &&
      Array.isArray(answer) &&
      question.displayChildTruthOption.some((opt) => answer.map((ans) => ans.text).includes(opt)))
  ) {
    return false;
  } else {
    return true;
  }
};

export const checkHideConditions = (question, answer, part = null) => {
  if (question.id == 16 && part) {
    return checkLMDCondition(part.hideConditions, answer);
  }
  if (question.id == 17 && part) {
    return checkLMDCondition(part.hideConditions, answer);
  }
};

const checkLMDCondition = (hideConditions, answer = {}) => {
  const currentDate = dayjs();
  const lmd = dayjs(answer["4b"]);
  let diff = currentDate.diff(lmd, "month");
  let LMPAnswer = answer["4a"];

  if (LMPAnswer == "Yes" && hideConditions["4_b"].includes(diff)) {
    return true;
  } else {
    return false;
  }
};
