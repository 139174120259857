import { t } from "@/intl/translation";
import SubmitBookingModal from "components/SubmitBookingModal";
import { getDoctorThumbnail } from "helpers/image-helper";
import useTranslation from "intl/useTranslation";
import { Mixpanel } from "lib/Mixpanel";
import Link from "next/link";
import { useRouter } from "next/router";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import RatingWithHearts from "TS_components/RatingWithHearts";
import { localize } from "utils/url";

import { getDoctorBookingType } from "@/utils/booking";
import {
  AvailabilityText,
  BookAppointmentLink,
  BookAppointmentText,
  BookIcon,
  ClinicLink,
  CredentialsContent,
  CredentialsItem,
  CTAContainer,
  DoctorCardGrouping,
  DoctorClinicIcon,
  DoctorImageContainer,
  DoctorInfoGrouping,
  DoctorName,
  DoctorPriceIcon,
  DoctorSpeciality,
  ImageInfoWrapper,
  LanguageIcon,
  LightningIcon,
  LinkInnerSpan,
  NextImage,
  NormalBookingLink,
  OfferTag,
  PopularTag,
  ProfileLink,
  TagsWrapper,
  VideoIcon,
  VideoIconContainer,
} from "./PopularDoctorCard.styles";

export const ImageInfo = ({ info }) => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  return (
    <ImageInfoWrapper>
      <DoctorImageContainer>
        <NextImage src={getDoctorThumbnail(info.slug, 70, 70, 95)} height={74} width={74} />
      </DoctorImageContainer>
      <DoctorInfoGrouping noGap>
        <RatingWithHearts rating={info.rating} />
        <DoctorName>{info.name}</DoctorName>
        <DoctorSpeciality>{info.specialization}</DoctorSpeciality>
      </DoctorInfoGrouping>
    </ImageInfoWrapper>
  );
};

export const DoctorTags = ({
  videoVisit,
  popular,
  offer,
}: {
  videoVisit: boolean;
  popular: boolean;
  offer: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <TagsWrapper>
      {videoVisit && (
        <VideoIconContainer>
          <VideoIcon /> {t("Video Visit")}
        </VideoIconContainer>
      )}
      {popular && <PopularTag>{t("Popular")}</PopularTag>}
      {offer && <OfferTag>{t("Special Offers")}</OfferTag>}
    </TagsWrapper>
  );
};

export const DoctorCredentials = ({ credentials }) => {
  const startingPriceValue = credentials.startingPrice?.split(" ")[1];
  const { client_side_country } = useSelector(({ InitialDataS }: any) => InitialDataS);
  const { locale } = useRouter();
  // prepend client side country if not qatar.
  const localizedLink = (clinicData) =>
    client_side_country ? `/${client_side_country}/clinic/${clinicData.slug_en}` : `/clinic/${clinicData.slug_en}`;

  const trackClinicLinkClick = (clinicName) => {
    const eventName = "Doctor Card | Clinic Name Clicked | Link";
    const eventProps = {
      "Page Type": "Home Page",
      "Doctor Name": credentials.name_en,
      "Speciality Name": credentials.specialization,
      "Clinic Name": clinicName,
    };

    Mixpanel.track(eventName, eventProps);
  };

  return (
    <DoctorCardGrouping margin="10px 0 20px 2px">
      <CredentialsItem>
        <LanguageIcon />
        {credentials.languages.map((language, index) => {
          return index === credentials.languages.length - 1 ? (
            <CredentialsContent>{language.name}</CredentialsContent>
          ) : (
            <CredentialsContent>{language.name}, </CredentialsContent>
          );
        })}
      </CredentialsItem>
      <CredentialsItem>
        <DoctorClinicIcon />
        <CredentialsContent inline>
          {credentials.clinics.map((clinic, index) => {
            return (
              <Fragment>
                <Link href={localizedLink(clinic)} locale={locale}>
                  <ClinicLink
                    onClick={(e) => {
                      e.stopPropagation();
                      trackClinicLinkClick(clinic.name_en);
                    }}
                    data-cy="doctor-card-clinic-link"
                  >
                    {clinic.name} {clinic.branch || ""}{" "}
                    {clinic?.area?.name && <LinkInnerSpan>({clinic.area.name})</LinkInnerSpan>}
                  </ClinicLink>
                </Link>
                {clinic?.other_branches?.length && <span> , </span>}
                {clinic.other_branches &&
                  clinic.other_branches.map((branch, Index) => (
                    <Fragment>
                      <Link href={localizedLink(clinic)} locale={locale}>
                        <ClinicLink
                          onClick={(e) => {
                            e.stopPropagation();
                            trackClinicLinkClick(branch.name_en);
                          }}
                          data-cy="doctor-card-clinic-link"
                        >
                          {branch.name} {branch.branch_name || ""}{" "}
                          {branch?.area?.name && <LinkInnerSpan>({branch.area.name})</LinkInnerSpan>}
                        </ClinicLink>
                      </Link>
                      {Index !== credentials.clinics.length - 1 && ","}
                    </Fragment>
                  ))}
                {index !== credentials.clinics.length - 1 && ","}
              </Fragment>
            );
          })}
        </CredentialsContent>
      </CredentialsItem>
      {startingPriceValue && (
        <CredentialsItem>
          <DoctorPriceIcon />
          <CredentialsContent>
            {t("Price starting from")}:{" "}
            {startingPriceValue === "free" || startingPriceValue === "0"
              ? t("Free consultation")
              : credentials.startingPrice?.split(" ").join("")}
          </CredentialsContent>
        </CredentialsItem>
      )}
    </DoctorCardGrouping>
  );
};

const renderBookingButton = (isInstant, instantBookingText, doesVideo, trackMixpanel) => {
  const { initData } = useSelector(({ InitialData }: any) => InitialData);
  const onClickHandler = () => {
    const isLoggedIn = initData.user_email ? "logged in" : "not logged in";
    const isModal = doesVideo ? "modal" : "no modal";
    trackMixpanel(`Book Now Button | Clicked (${isLoggedIn}, ${isModal}) | Button`);
  };

  return isInstant ? (
    <BookAppointmentLink data-cy="doctor-card-book-now" onClick={onClickHandler}>
      <BookAppointmentText>
        <LightningIcon />
        {t("Book Now")}
      </BookAppointmentText>
      <AvailabilityText>
        {t("Available")}&nbsp;
        {instantBookingText}
      </AvailabilityText>
    </BookAppointmentLink>
  ) : (
    <NormalBookingLink onClick={onClickHandler} data-cy="doctor-card-book-now">
      <BookIcon /> {t("Book Now")}
    </NormalBookingLink>
  );
};

export const CTA = ({ data }) => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const { client_side_country, language_code } = useSelector(({ InitialDataS }: any) => InitialDataS);

  // value === 'free' || value === '0' ? 'Free consultation' : credentials.startingPrice.split(' ').join('')
  const instantBooking = data.nextAvailable.first_available;
  const walkIn = data.nextAvailable.walkIn;

  const trackMixpanel = (name) => {
    const eventName = name;
    const eventProps = {
      "Page Type": "Home Page",
      Locale: client_side_country || "qatar",
      "Speciality Name": data.specialization,
      "Doctor Name": data.name_en,
    };

    Mixpanel.track(eventName, eventProps);
  };

  const bookingCategory = getDoctorBookingType(data);

  return (
    <CTAContainer>
      <Link href={`/${localize(`${data.slug}`, client_side_country, language_code)}`}>
        <ProfileLink
          fillContainer={!data.bookable}
          data-cy="doctor-card-view-profile"
          onClick={(e) => {
            e.stopPropagation();
            trackMixpanel("Doctor Card | View Profile Clicked | Button");
          }}
        >
          {t("View Profile")}
        </ProfileLink>
      </Link>
      {data.bookable && data.videoVisit && (
        <SubmitBookingModal
          slug={`/bookings/submit_booking/doctor/${data.slug}`}
          doctorName={data.name}
          rating={data.rating}
          numReviews={data.num_reviews}
          startingPrice={data.startingPrice}
          videoVisit={data.videoVisit}
          specialization={data.specialization}
          bookingTypes={data.visit_types}
          insurance={data.insurance || Boolean(data.all_insurances?.length > 0)}
          imageLink={getDoctorThumbnail(data.slug, 70, 70, 95)}
          isDoctorProfile={false}
          isCallSupportActive={false}
          doctorData={data}
          bookingCategory={bookingCategory}
        >
          {renderBookingButton(
            Boolean(instantBooking || walkIn),
            instantBooking,
            data.videoVisit == true,
            trackMixpanel
          )}
        </SubmitBookingModal>
      )}
      {data.bookable && !data.videoVisit && (
        <Link
          href={`/${localize(
            `bookings/submit_booking/doctor/${data.slug}/?visitType=clinic${
              bookingCategory ? `&bookingType=${bookingCategory}` : ""
            }`,
            client_side_country,
            language_code
          )}`}
        >
          {renderBookingButton(
            Boolean(instantBooking || walkIn),
            instantBooking,
            data.videoVisit == true,
            trackMixpanel
          )}
        </Link>
      )}
    </CTAContainer>
  );
};
