import useTranslation from 'intl/useTranslation';
import { useEffect, useState } from 'react';

export const useLoadDiscounts = ({ discounts, DiscountTypes, DiscountTypeLinks, discountCategoriesData }) => {
  const { t } = useTranslation();
  const [discountsList, setDiscountsList] = useState({});
  const [filterLinks, setFilterLinks] = useState([]);
  let currentDiscountList = [];
  let currentFilterLinks = [];
  if (discounts) {
    Object.keys(DiscountTypes).forEach((key) => {
      if (discounts[DiscountTypes[key]]?.length) {
        let keyName = t(key) || key;
        currentDiscountList[keyName] = discounts[DiscountTypes[key]];
        currentFilterLinks[keyName] = 'type=' + DiscountTypeLinks[key];
      }
    });
  }

  if (discountCategoriesData) {
    Object.keys(discountCategoriesData).forEach((key) => {
      let keyName = t(key) || key;
      currentDiscountList[keyName] = discountCategoriesData[key];
      currentFilterLinks[keyName] =
        'category=' + discounts['discount_categories'].filter((category) => category.name == key)[0]?.slug;
    });
  }

  useEffect(() => {
    setDiscountsList(currentDiscountList);
    setFilterLinks(currentFilterLinks);
  }, [discounts, discountCategoriesData]);

  return { discountsList, filterLinks };
};
