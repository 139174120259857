export enum DiscountTypes {
  'Best Savers' = 'best_saving_discounts',
  'Expiring Soon' = 'expiring_soon_discounts',
  'Recommended' = 'recommended_discounts',
}

export enum DiscountTypeLinks {
  'Best Savers' = 'best_saving',
  'Expiring Soon' = 'expiring__soon',
  'Recommended' = 'recommended_discounts',
}
