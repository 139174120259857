import styled from "styled-components";

export const DateInputContainer = styled.div<{ selected: boolean }>`
  width: 100%;
  margin-bottom: 40px;

  .react-datepicker {
    z-index: 12222;
    font-size: 1em;
    font-weight: 400;
    background-color: white;
  }

  .react-datepicker__tab-loop > .react-datepicker-popper {
    @include add-to-arabic {
      left: auto !important;
      right: 30px;
    }
    z-index: 10;
  }
  .react-datepicker__header {
    padding-top: 0.8em;
    background-color: white;
    border-bottom: 0px;
  }
  .react-datepicker__month {
    margin: 0.4em 1em;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__day-name,
  .react-datepicker__month-read-view--selected-month,
  .react-datepicker__year-read-view--selected-year,
  .react-datepicker__current-month {
    color: #2a3390;
  }
  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 1.9em;
    line-height: 1.9em;
    margin: 0.166em;
  }

  .react-datepicker__day--selected {
    background-color: #63be7b;
  }
  .react-datepicker__current-month {
    font-size: 1em;
  }
  .react-datepicker__navigation {
    top: 1em;
    line-height: 1.7em;
    border: 0.45em solid transparent;
  }
  .react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1em;
  }
  .react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em;
  }

  .react-datepicker__month-dropdown {
    background-color: white;
  }

  .react-datepicker__year-dropdown {
    background-color: white;
  }

  .decrease-month,
  .increase-month {
    background: #fff;
    border: none;
  }

  .bmgf-date {
    padding: 10px 0px 10px 32px !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    height: auto !important;

    &::placeholder {
      color: ${({ selected }) => (selected ? "#282828" : "#A2A3A6")} !important;
      opacity: 1 !important;
    }

    &::-webkit-input-placeholder {
      color: ${({ selected }) => (selected ? "#282828" : "#A2A3A6")} !important;
    }
    &:-ms-input-placeholder {
      color: ${({ selected }) => (selected ? "#282828" : "#A2A3A6")} !important;
    }

    &::-ms-input-placeholder {
      color: ${({ selected }) => (selected ? "#282828" : "#A2A3A6")} !important;
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
  }
`;
export const DateInputQuestionHeader = styled.div`
  display: flex;
`;
export const DateInputQuestionNumber = styled.p`
  font-size: 16px !important;
`;
export const DateInputLabel = styled.span`
  font-size: 16px !important;
  font-weight: bold !important;
  margin-left: 5px !important;
`;

export const DateInputParent = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;
export const DateInput = styled.input`
  width: 100% !important;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  padding: 10px 0px 10px 16px;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder {
    color: #a2a3a6;
    opacity: 1;
  }

  :focus {
    outline: none !important;
    border: 1px solid #2a3390;
    border-radius: 5px;
  }
`;

export const DateInputSubPlaceHolder = styled.span`
  margin-left: -11%;
  color: #282828 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  position: absolute;
  top: 10px;
  right: 15px;
`;

export const DateInputError = styled.p`
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #d73e3e !important;
  margin: 10px 0px 0px 0px !important;
`;

export const BMGFCalendarInputContainer = styled.div`
  position: relative;
`;
