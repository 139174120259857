import { PhoneInput, isValidPhoneNumber } from "TS_components/NewPhoneInput/PhoneInput";
import { FC } from "react";
import { HeliumLogoGrey } from "./BMGF.icons";
import {
  BMGFEndContainer,
  BMGFEndContainerText,
  BMGFPhoneInputContainer,
  BMGFPhoneInputError,
  BMGFPhoneInputFooter,
  BMGFPhoneInputHeader,
} from "./BMGF.styles";
import BMGFAreaInput from "./BMGFAreaInput";
import BMGFBooleanInput from "./BMGFBooleanInput";
import BMGFDateInput from "./BMGFDateInput";
import { checkHideConditions, skipWholeQuestion } from "./BMGFHelper";
import BMGFMultiInput from "./BMGFMultiInput";
import BMGFRadioInput from "./BMGFRadioInput";
import BMGFTextInput from "./BMGFTextInput";
interface StepsProps {
  updateAnswers: (data: any) => void;
  answers: any;
  setBookingNumber?: (number: string) => void;
  showPhoneInput?: boolean;
  step: number;
  maxStep: number;
  shouldShow?: (answers: any, currentStepNumber: string) => boolean;
  questionsData: any;
  setIsValidNumber?: (isInvalid: boolean) => void;
  showNumberError?: boolean;
  questionsCategories?: any;
  patiendInfoValidation?: any;
  mappedAnswers?: any;
  phoneNumber?: string;
}

interface BMGFFieldProps {
  questionNumber: string;
  questionNumberToShow: string;
  question: string;
  placeholder?: string;
  updateAnswers: () => void;
  value: any;
  inputType: string;
  items?: Array<any>;
  subPlaceHolder: string;
  invalid?: boolean;
  options?: any;
}
const BMGFTextInputField = (data: BMGFFieldProps) => {
  return (
    <BMGFTextInput
      questionNumber={data.questionNumber}
      questionText={data.question}
      options={data.options}
      placeholder={data.placeholder}
      updateAnswers={data.updateAnswers}
      value={data?.value}
      type={data.inputType}
      invalid={data.invalid}
      subPlaceHolder={data.subPlaceHolder}
      questionNumberToShow={data.questionNumberToShow}
    ></BMGFTextInput>
  );
};

const BMGFRadioInputField = (data: BMGFFieldProps) => {
  return (
    <BMGFRadioInput
      questionNumber={data.questionNumber}
      questionText={data.question}
      updateAnswers={data.updateAnswers}
      value={data.value}
      type={data.inputType}
      items={data.items}
      invalid={data.invalid}
      options={data.options}
      questionNumberToShow={data.questionNumberToShow}
    ></BMGFRadioInput>
  );
};

const BMGFBooleanInputField = (data: BMGFFieldProps) => {
  return (
    <BMGFBooleanInput
      questionNumber={data.questionNumber}
      questionText={data.question}
      updateAnswers={data.updateAnswers}
      value={data.value}
      invalid={data.invalid}
      options={data.options}
      questionNumberToShow={data.questionNumberToShow}
    ></BMGFBooleanInput>
  );
};

const BMGFDateInputField = (data: BMGFFieldProps) => {
  return (
    <BMGFDateInput
      questionNumber={data.questionNumber}
      questionText={data.question}
      updateAnswers={data.updateAnswers}
      value={data.value}
      invalid={data.invalid}
      type={data.inputType}
      questionNumberToShow={data.questionNumberToShow}
    ></BMGFDateInput>
  );
};

export const BMGFMultiInputField = (data: BMGFFieldProps) => {
  return (
    <BMGFMultiInput
      questionNumber={data.questionNumber}
      questionText={data.question}
      updateAnswers={data.updateAnswers}
      value={data.value}
      type={data.type}
      items={data.options}
      invalid={data.invalid}
      questionNumberToShow={data.questionNumberToShow}
    ></BMGFMultiInput>
  );
};
export const Steps: FC<StepsProps> = ({
  updateAnswers,
  answers = {},
  step,
  questionsData,
  showPhoneInput,
  setBookingNumber,
  setIsValidNumber,
  showNumberError,
  questionsCategories,
  patiendInfoValidation,
  maxStep,
  mappedAnswers,
  phoneNumber,
}: StepsProps) => {
  let currentStepNumber = "Step" + step;
  let questionIndex = 1;
  // useEffect(() => {
  //   const pageScroll = (top = 0, left = 0) => {
  //     window.scroll({
  //       top: top,
  //       left: left,
  //       behavior: 'smooth',
  //     });
  //   };
  //   pageScroll();
  // }, []);

  return (
    <>
      {questionsData.map((questionData) => {
        if (
          questionsCategories[step - 1] != questionData.category &&
          !skipWholeQuestion(questionData, mappedAnswers) &&
          !checkHideConditions(questionData, mappedAnswers, questionData.questions[0])
        ) {
          questionIndex++;
          return;
        }

        let hideChild = false;
        let hasChildren = false;
        let showQuestionPart = false;
        if (
          questionData.hideConditions &&
          (skipWholeQuestion(questionData, mappedAnswers) || checkHideConditions(questionData, mappedAnswers))
        ) {
          return;
          // Object.keys(questionData.hideConditions).forEach((key) => {
          //   let splittedKey = key.split("_").join("");
          //   Object.keys(answers).forEach((step) => {
          //     let answerToCheck = answers[step][splittedKey];
          //     if (
          //       Array.isArray(answerToCheck) &&
          //       answerToCheck.some((answer) => questionData.hideConditions[key].includes(answer))
          //     ) {
          //       skipWholeQuestion = true;
          //     } else if (answerToCheck && questionData.hideConditions[key].includes(answerToCheck)) {
          //       skipWholeQuestion = true;
          //     }
          //   });
          // });
        }

        let elements = questionData.questions.map((question) => {
          // if (hideChild) {
          //   return <></>;
          // }

          if (
            checkHideConditions(questionData, mappedAnswers, question) ||
            skipWholeQuestion(questionData, mappedAnswers, question)
          ) {
            return <></>;
          }

          let questionNumber = questionData.id + question.number;
          question.questionNumber = questionData.id + question.number;
          question.updateAnswers = updateAnswers;
          question.value = answers?.[currentStepNumber]?.[questionNumber];

          let answer = answers?.[currentStepNumber]?.[questionNumber];
          if (questionData.questions.length > 1) {
            hasChildren = true;
            question.questionNumberToShow = questionIndex + question.number;
          } else {
            question.questionNumberToShow = questionIndex;
          }

          // hideChild = shallHideChild(question, answer);
          showQuestionPart = true;
          // if (
          //   (question.hasChild && question.displayChildTruthOption.includes(answer)) ||
          //   (question.hasChild && question.displayChildTruthOption.includes(parseInt(answer))) ||
          //   (question.hasChild &&
          //     Array.isArray(answer) &&
          //     question.displayChildTruthOption.some((opt) => answer.map((ans) => ans.text).includes(opt))) ||
          //   question.displayChildTruthOption.length == 0
          // ) {
          //   hideChild = false;
          // } else {
          //   hideChild = true;
          // }

          if (question.inputType == "number") {
            return BMGFTextInputField(question);
          }
          if (question.inputType == "radio") {
            question.inputType = "radio";
            return BMGFRadioInputField(question);
          }
          if (question.inputType == "boolean") {
            return BMGFBooleanInputField(question);
          }
          if (question.inputType == "date") {
            return BMGFDateInputField(question);
          }
          if (question.inputType == "multi") {
            return BMGFMultiInputField(question);
          }
        });

        if (showQuestionPart) {
          questionIndex++;
        }

        return elements;
      })}

      {/* {StepQuestionsOrder[currentStepNumber].map((key) => {
        let currentQuestion = { ...StepQuestionsObject[currentStepNumber][key] } || {};
        currentQuestion.value = answers?.[currentStepNumber]?.[key];

        currentQuestion.updateAnswers = updateAnswers;
        currentQuestion.invalid = questions?.[currentStepNumber]?.[key].invalid;

        if (currentQuestion.shouldShow && currentQuestion.shouldShow(answers, currentStepNumber) == false) {
          return <></>;
        }

        if (currentQuestion.type == 'text' || currentQuestion.type == 'number') {
          return BMGFTextInputField(currentQuestion);
        }

        if (currentQuestion.type == 'area') {
          return BMGFAreaInput(currentQuestion);
        }
        if (currentQuestion.type == 'date') {
          return BMGFDateInput(currentQuestion);
        }

        if (currentQuestion.type == 'radio') {
          return BMGFRadioInputField(currentQuestion);
        }
        if (currentQuestion.type == 'boolean') {
          return BMGFBooleanInputField(currentQuestion);
        }

        if (currentQuestion.type == 'multi') {
          return BMGFMultiInputField(currentQuestion);
        }
      })} */}

      {step == maxStep && (
        <BMGFEndContainer>
          <HeliumLogoGrey></HeliumLogoGrey>
          <BMGFEndContainerText>This is the end of the questionnaire</BMGFEndContainerText>
        </BMGFEndContainer>
      )}

      {step == maxStep && (
        <>
          <BMGFTextInput
            updateAnswers={(ans) => {
              updateAnswers(ans);
            }}
            questionNumber="patient_name"
            value={answers["Step" + maxStep]?.["patient_name"]}
            questionText="Respondent Full Name"
            placeholder={"Enter your full name"}
            invalid={patiendInfoValidation.patient_name.invalid}
            hideQuestionNumber={true}
          ></BMGFTextInput>
          <BMGFAreaInput
            updateAnswers={(ans) => {
              updateAnswers(ans);
            }}
            questionNumber="patient_state"
            value={answers["Step" + maxStep]?.["patient_state"]}
            questionText="Respondent State & LGA"
            placeholder={"Choose State & LGA"}
            invalid={patiendInfoValidation.patient_state.invalid}
            hideQuestionNumber={true}
          ></BMGFAreaInput>
        </>
      )}

      {step == maxStep && showPhoneInput && (
        <BMGFPhoneInputContainer>
          <BMGFPhoneInputHeader>Mobile</BMGFPhoneInputHeader>
          <PhoneInput
            readOnly={true}
            value={phoneNumber}
            onChange={(val) => {
              setBookingNumber(val);
              setIsValidNumber(isValidPhoneNumber(val));
            }}
            placeholder={"Add your Mobile Number"}
            defaultCountry={"NG"}
          />
          <BMGFPhoneInputFooter>
            We will send you a copy of the results by SMS. We take your privacy very seriously.
          </BMGFPhoneInputFooter>
          {showNumberError && <BMGFPhoneInputError>Number is not valid</BMGFPhoneInputError>}
        </BMGFPhoneInputContainer>
      )}
      {/* <BMGFTextInput
        questionNumber={step1['1a'].questionNumber}
        questionText={step1['1a'].questionText}
        placeholder={step1['1a'].placeholder}
        updateAnswers={updateAnswers}
        value={answers?.['1a']}
        type={step1['1a'].type}
      ></BMGFTextInput>
      <BMGFRadioInput
        questionNumber={step1['2a'].questionNumber}
        questionText={step1['2a'].questionText}
        updateAnswers={updateAnswers}
        value={answers?.['2a']}
        type={step1['2a'].type}
        items={step1['2a'].items}
      ></BMGFRadioInput>

      {answers?.['2a'] && ['Single', 'Divorced', 'Widowed'].includes(answers?.['2a']) && (
        <BMGFBooleanInput
          questionNumber={step1['2b'].questionNumber}
          questionText={step1['2b'].questionText}
          updateAnswers={updateAnswers}
          value={answers?.['2b']}
        ></BMGFBooleanInput>
      )} */}
    </>
  );
};

// export const Step2: FC<StepsProps> = ({ updateAnswers, answers }: StepsProps) => {
//   let step2 = StepQuestionsObject['Step2'];
//   return (
//     <>
//       <BMGFTextInput
//         questionNumber={step2['3a'].questionNumber}
//         questionText={step2['3a'].questionText}
//         placeholder={step2['3a'].placeholder}
//         updateAnswers={updateAnswers}
//         value={answers?.['3a']}
//         subPlaceHolder={step2['3a'].subPlaceHolder}
//         type={step2['3a'].type}
//         maxValue={step2['3a'].maxValue}
//       ></BMGFTextInput>

//       <BMGFBooleanInput
//         questionNumber={step2['3b'].questionNumber}
//         questionText={step2['3b'].questionText}
//         updateAnswers={updateAnswers}
//         value={answers?.['3b']}
//       ></BMGFBooleanInput>

//       {answers?.['3b'] && answers?.['3b'] == 'no' && (
//         <BMGFTextInput
//           questionNumber={step2['3c'].questionNumber}
//           questionText={step2['3c'].questionText}
//           placeholder={step2['3c'].placeholder}
//           updateAnswers={updateAnswers}
//           value={answers?.['3c']}
//           subPlaceHolder={step2['3c'].subPlaceHolder}
//           type={step2['3c'].type}
//         ></BMGFTextInput>
//       )}

//       <BMGFRadioInput
//         questionNumber={step2['4'].questionNumber}
//         questionText={step2['4'].questionText}
//         updateAnswers={updateAnswers}
//         value={answers?.['4']}
//         type={step2['4'].type}
//         items={step2['4'].items}
//       ></BMGFRadioInput>

//       <BMGFBooleanInput
//         questionNumber={step2['5'].questionNumber}
//         questionText={step2['5'].questionText}
//         updateAnswers={updateAnswers}
//         value={answers?.['5']}
//       ></BMGFBooleanInput>

//       <BMGFBooleanInput
//         questionNumber={step2['6a'].questionNumber}
//         questionText={step2['6a'].questionText}
//         updateAnswers={updateAnswers}
//         value={answers?.['6a']}
//       ></BMGFBooleanInput>

//       {answers?.['6a'] && answers?.['6a'] == 'yes' && (
//         <BMGFTextInput
//           questionNumber={step2['6b'].questionNumber}
//           questionText={step2['6b'].questionText}
//           placeholder={step2['6b'].placeholder}
//           updateAnswers={updateAnswers}
//           value={answers?.['6b']}
//           subPlaceHolder={step2['6b'].subPlaceHolder}
//           type={step2['6b'].type}
//         ></BMGFTextInput>
//       )}

//       {answers?.['6a'] && answers?.['6a'] == 'yes' && (
//         <BMGFBooleanInput
//           questionNumber={step2['6c'].questionNumber}
//           questionText={step2['6c'].questionText}
//           updateAnswers={updateAnswers}
//           value={answers?.['6c']}
//         ></BMGFBooleanInput>
//       )}

//       {answers?.['6a'] && answers?.['6a'] == 'yes' && (
//         <BMGFTextInput
//           questionNumber={step2['7'].questionNumber}
//           questionText={step2['7'].questionText}
//           placeholder={step2['7'].placeholder}
//           updateAnswers={updateAnswers}
//           value={answers?.['7']}
//           subPlaceHolder={step2['7'].subPlaceHolder}
//           type={step2['7'].type}
//         ></BMGFTextInput>
//       )}

//       <BMGFBooleanInput
//         questionNumber={step2['8a'].questionNumber}
//         questionText={step2['8a'].questionText}
//         updateAnswers={updateAnswers}
//         value={answers?.['8a']}
//       ></BMGFBooleanInput>

//       {answers?.['8a'] && answers?.['8a'] == 'yes' && (
//         <BMGFTextInput
//           questionNumber={step2['8b'].questionNumber}
//           questionText={step2['8b'].questionText}
//           placeholder={step2['8b'].placeholder}
//           updateAnswers={updateAnswers}
//           value={answers?.['8b']}
//           subPlaceHolder={step2['8b'].subPlaceHolder}
//           type={step2['8b'].type}
//         ></BMGFTextInput>
//       )}

//       <BMGFBooleanInput
//         questionNumber={step2['9a'].questionNumber}
//         questionText={step2['9a'].questionText}
//         updateAnswers={updateAnswers}
//         value={answers?.['9a']}
//       ></BMGFBooleanInput>

//       {answers?.['9a'] && answers?.['9a'] == 'yes' && (
//         <BMGFTextInput
//           questionNumber={step2['9b'].questionNumber}
//           questionText={step2['9b'].questionText}
//           placeholder={step2['9b'].placeholder}
//           updateAnswers={updateAnswers}
//           value={answers?.['9b']}
//           type={step2['9b'].type}
//         ></BMGFTextInput>
//       )}

//       {answers?.['9a'] && answers?.['9a'] == 'yes' && (
//         <BMGFRadioInput
//           questionNumber={step2['9c'].questionNumber}
//           questionText={step2['9c'].questionText}
//           updateAnswers={updateAnswers}
//           value={answers?.['9c']}
//           type={step2['9c'].type}
//           items={step2['9c'].items}
//         ></BMGFRadioInput>
//       )}

//       <BMGFBooleanInput
//         questionNumber={step2['10a'].questionNumber}
//         questionText={step2['10a'].questionText}
//         updateAnswers={updateAnswers}
//         value={answers?.['10a']}
//       ></BMGFBooleanInput>

//       {answers?.['10a'] && answers?.['10a'] == 'yes' && (
//         <BMGFTextInput
//           questionNumber={step2['10b'].questionNumber}
//           questionText={step2['10b'].questionText}
//           placeholder={step2['10b'].placeholder}
//           updateAnswers={updateAnswers}
//           value={answers?.['10b']}
//           type={step2['10b'].type}
//           subPlaceHolder={step2['10b'].subPlaceHolder}
//         ></BMGFTextInput>
//       )}

//       <BMGFBooleanInput
//         questionNumber={step2['11'].questionNumber}
//         questionText={step2['11'].questionText}
//         updateAnswers={updateAnswers}
//         value={answers?.['11']}
//       ></BMGFBooleanInput>

//       <BMGFRadioInput
//         questionNumber={step2['12'].questionNumber}
//         questionText={step2['12'].questionText}
//         updateAnswers={updateAnswers}
//         value={answers?.['12']}
//         type={step2['12'].type}
//         items={step2['12'].items}
//       ></BMGFRadioInput>
//     </>
//   );
// };

// export const Step3: FC<StepsProps> = ({ updateAnswers, answers }: StepsProps) => {
//   let step3 = StepQuestionsObject['Step3'];
//   return (
//     <>
//       <BMGFMultiInput
//         questionNumber={step3['13'].questionNumber}
//         questionText={step3['13'].questionText}
//         updateAnswers={updateAnswers}
//         value={answers?.['13']}
//         type={step3['13'].type}
//         items={step3['13'].items}
//       ></BMGFMultiInput>

//       <BMGFMultiInput
//         questionNumber={step3['14'].questionNumber}
//         questionText={step3['14'].questionText}
//         updateAnswers={updateAnswers}
//         value={answers?.['14']}
//         type={step3['14'].type}
//         items={step3['14'].items}
//       ></BMGFMultiInput>

//       <BMGFMultiInput
//         questionNumber={step3['15'].questionNumber}
//         questionText={step3['15'].questionText}
//         updateAnswers={updateAnswers}
//         value={answers?.['15']}
//         type={step3['15'].type}
//         items={step3['15'].items}
//       ></BMGFMultiInput>

//       <BMGFMultiInput
//         questionNumber={step3['16a'].questionNumber}
//         questionText={step3['16a'].questionText}
//         updateAnswers={updateAnswers}
//         value={answers?.['16a']}
//         type={step3['16a'].type}
//         items={step3['16a'].items}
//       ></BMGFMultiInput>

//       {answers?.['16a'] &&
//         !answers?.['16a']?.includes('I haven’t experienced any of these symptoms') &&
//         answers?.['16a'].length > 0 && (
//           <BMGFTextInput
//             questionNumber={step3['16b'].questionNumber}
//             questionText={step3['16b'].questionText}
//             placeholder={step3['16b'].placeholder}
//             updateAnswers={updateAnswers}
//             value={answers?.['16b']}
//             type={step3['16b'].type}
//             subPlaceHolder={step3['16b'].subPlaceHolder}
//           ></BMGFTextInput>
//         )}

//       <BMGFMultiInput
//         questionNumber={step3['17'].questionNumber}
//         questionText={step3['17'].questionText}
//         updateAnswers={updateAnswers}
//         value={answers?.['17']}
//         type={step3['17'].type}
//         items={step3['17'].items}
//       ></BMGFMultiInput>

//       <BMGFMultiInput
//         questionNumber={step3['18'].questionNumber}
//         questionText={step3['18'].questionText}
//         updateAnswers={updateAnswers}
//         value={answers?.['18']}
//         type={step3['18'].type}
//         items={step3['18'].items}
//       ></BMGFMultiInput>

//       <BMGFBooleanInput
//         questionNumber={step3['19'].questionNumber}
//         questionText={step3['19'].questionText}
//         updateAnswers={updateAnswers}
//         value={answers?.['19']}
//       ></BMGFBooleanInput>

//       <BMGFMultiInput
//         questionNumber={step3['20'].questionNumber}
//         questionText={step3['20'].questionText}
//         updateAnswers={updateAnswers}
//         value={answers?.['20']}
//         type={step3['20'].type}
//         items={step3['20'].items}
//       ></BMGFMultiInput>
//     </>
//   );
// };
// export const Step4: FC<StepsProps> = ({ updateAnswers, answers, setBookingNumber, showPhoneInput }: StepsProps) => {
//   let step4 = StepQuestionsObject['Step4'];
//   return (
//     <>
//       <BMGFMultiInput
//         questionNumber={step4['21'].questionNumber}
//         questionText={step4['21'].questionText}
//         updateAnswers={updateAnswers}
//         value={answers?.['21']}
//         type={step4['21'].type}
//         items={step4['21'].items}
//       ></BMGFMultiInput>

//       <BMGFMultiInput
//         questionNumber={step4['22'].questionNumber}
//         questionText={step4['22'].questionText}
//         updateAnswers={updateAnswers}
//         value={answers?.['22']}
//         type={step4['22'].type}
//         items={step4['22'].items}
//       ></BMGFMultiInput>
//       <BMGFMultiInput
//         questionNumber={step4['23'].questionNumber}
//         questionText={step4['23'].questionText}
//         updateAnswers={updateAnswers}
//         value={answers?.['23']}
//         type={step4['23'].type}
//         items={step4['23'].items}
//       ></BMGFMultiInput>
//       <BMGFEndContainer>
//         <HeliumLogoGrey></HeliumLogoGrey>
//         <BMGFEndContainerText>This is the end of the questionnaire</BMGFEndContainerText>
//       </BMGFEndContainer>

//       {showPhoneInput && (
//         <BMGFPhoneInputContainer>
//           <BMGFPhoneInputHeader>Mobile</BMGFPhoneInputHeader>
//           <IntlTelInput
//             containerClassName="intl-tel-input"
//             inputClassName="form-control bmgf-tel-input"
//             preferredCountries={['qa']}
//             placeholder={'Add your Mobile Number'}
//             onPhoneNumberChange={(isValidNumber, number, countryData, fullNumber) => {
//               setBookingNumber(fullNumber);
//               // setDialCode(countryData.dialCode);
//               // setBookingNumber(number);
//               // if (
//               //   countryData.iso2 === 'ke' &&
//               //   countryData.dialCode === '254' &&
//               //   number &&
//               //   ['10', '010', '11', '011'].some((prefix) => number.startsWith(prefix)) &&
//               //   !isNaN(+number) &&
//               //   String(+number).length === 9
//               // ) {
//               //   setIsInValid(false);
//               // } else {
//               //   setIsInValid(!isValidNumber);
//               // }
//             }}
//           />
//           <BMGFPhoneInputFooter>
//             We will send you a copy of the results by SMS. We take your privacy very seriously.
//           </BMGFPhoneInputFooter>
//         </BMGFPhoneInputContainer>
//       )}
//     </>
//   );
// };
