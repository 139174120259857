import useTranslation from "@/intl/useTranslation";
import { Mixpanel } from "@/utils";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { DownLoadAppButton } from "./DownloadAppSection";
import { AppStoreBadge, GooglePlayBadge } from "./DownloadAppSection.icons";

export const DownloadAppButtons = ({ appleUrl, googleUrl }: { appleUrl: string; googleUrl: string }) => {
  const { client_side_country, language_code } = useSelector(({ InitialDataS }: any) => InitialDataS);
  const { locale } = useTranslation();
  const trackMixpanel = (eventName, eventProps) => {
    Mixpanel.track(eventName, eventProps);
  };
  const { push } = useRouter();
  return (
    <>
      <DownLoadAppButton
        onClick={() => {
          trackMixpanel("Homepage | Download iOS App | Button", {
            Locale: client_side_country || "qatar",
            Language: locale.toUpperCase(),
          });
          push(appleUrl);
        }}
      >
        <AppStoreBadge />
      </DownLoadAppButton>
      <DownLoadAppButton
        onClick={() => {
          trackMixpanel("Homepage | Download Android App | Button", {
            Locale: client_side_country || "qatar",
            Language: locale.toUpperCase(),
          });
          push(googleUrl);
        }}
      >
        <GooglePlayBadge />
      </DownLoadAppButton>
    </>
  );
};
