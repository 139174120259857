import DangerIndicator from "public/next-images/Danger.svg";
import GreenIndicator from "public/next-images/Green-indicator.svg";
import mapIcon from "public/next-images/Map-icon.svg";
import polygon from "public/next-images/Polygon-top.svg";
import BMGFRiskBarMobile from "public/next-images/Riskbar-mobile.svg";
import WarningIndicator from "public/next-images/Warning.svg";
import styled from "styled-components";

export const IndicatorGreen = styled(GreenIndicator)``;

export const RiskBar = styled.div`
  min-height: 10px;
  max-height: 10px;
  background: linear-gradient(270deg, #d73e3e 6.03%, #ffbb3d 50.05%, #63be7b 94.93%);
  border-radius: 100px;
`;
export const RiskBarMobile = styled(BMGFRiskBarMobile)``;

export const Polygon = styled(polygon)``;

export const MapIcon = styled(mapIcon)``;

export const IndicatorDanger = styled(DangerIndicator)``;

export const IndicatorWarning = styled(WarningIndicator)``;
