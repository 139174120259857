import styled from "styled-components";

export const TextInputContainer = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;
export const TextInputQuestionHeader = styled.div`
  display: flex;
`;
export const TextInputQuestionNumber = styled.p`
  font-size: 16px !important;
`;
export const TextInputLabel = styled.span`
  font-size: 16px !important;
  font-weight: bold !important;
  margin-left: 5px !important;
`;

export const TextInputParent = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;
export const TextInput = styled.input`
  width: 100% !important;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  padding: 10px 0px 10px 16px;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder {
    color: #a2a3a6;
    opacity: 1;
  }

  :focus {
    outline: none !important;
    border: 1px solid #2a3390;
    border-radius: 5px;
  }
`;

export const TextInputSubPlaceHolder = styled.span`
  margin-left: -11%;
  color: #282828 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  position: absolute;
  top: 10px;
  right: 15px;
`;

export const TextInputError = styled.p`
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #d73e3e !important;
  margin: 10px 0px 0px 0px !important;
`;
