import ChevronDownIcon from "TS_components/HomeHero/icons/ChevronDownIcon";
import { FC, useEffect, useState } from "react";
import CountriesDropdown from "../../HomeHero/CountriesDropdown";
import {
  AreaInputContainer,
  AreaInputError,
  AreaInputLabel,
  AreaInputQuestionHeader,
  AreaInputQuestionNumber,
  AreaInputSelectButton,
  AreaInputSelectButtonCopyContainer,
  AreaInputSelectButtonLabel,
  AreaInputSelectButtonText,
} from "./BMGFAreaInput.styles";

import { _GetLGAByState } from "TS_Services/PregnancyRiskAssessment";
import { states } from "./BMGFAreaInput.areas";

interface BMGFTextInputProps {
  questionNumber: string;
  questionNumberToShow?: string;
  questionText: string;
  value: string;
  placeholder?: string;
  updateAnswers: (data: any) => void;
  subPlaceHolder?: string;
  type: string;
  maxValue?: number;
  invalid?: boolean;
  hideQuestionNumber?: boolean;
}

const BMGFAreaInput: FC<BMGFTextInputProps> = ({
  questionNumber,
  questionNumberToShow,
  questionText,
  value,
  placeholder,
  updateAnswers,
  subPlaceHolder,
  type,
  maxValue,
  invalid,
  hideQuestionNumber = false,
}) => {
  const [label, setLabel] = useState();
  const [currentValue, setCurrentValue] = useState(value || {});
  const [fetchedStates, setFetchedStates] = useState([]);

  useEffect(async () => {
    let currentStates = [];
    for (const state of states) {
      await _GetLGAByState(state.id).then((data) => {
        const stateObj = {
          name: state.name,
          value: state.name,
          name_en: state.name,
          areas: data.map((area) => {
            return {
              name: area.name,
              value: area.name,
              name_en: area.name,
            };
          }),
        };
        currentStates.push(stateObj);
      });
    }
    setFetchedStates(currentStates);
  }, []);

  const buildLabel = (e = {}) => {
    let value = e.city;
    if (e.area) {
      value += ", " + e.area;
    }
    setLabel(value);
  };
  useEffect(() => {
    buildLabel(value);
  }, [value]);

  useEffect(() => {
    let tempValue = currentValue?.city;
    if (currentValue?.area) {
      tempValue += ", " + currentValue.area;
    }

    setLabel(tempValue);
  }, [currentValue]);

  return (
    <AreaInputContainer key={Math.random()}>
      <AreaInputQuestionHeader>
        <AreaInputQuestionNumber>
          {hideQuestionNumber ? "" : questionNumberToShow + "."}
          <AreaInputLabel>{questionText}</AreaInputLabel>
        </AreaInputQuestionNumber>
      </AreaInputQuestionHeader>

      <CountriesDropdown
        hideAllKeywordOption={true}
        areas={[]}
        cities={fetchedStates}
        placeholder={"Choose State and Area"}
        position={"relative"}
        setValue={(e: DropdownSelectData) => {
          // let value = buildLabel(e);
          if (e.city && e.area) {
            updateAnswers({ [questionNumber]: e });
            // setLabel(value);
          }
        }}
        triggerer={({ labelName, ...props }) => (
          <AreaInputSelectButton ariaExpanded={props["aria-expanded"]} {...props}>
            {/* <PinIcon /> */}
            <AreaInputSelectButtonCopyContainer>
              {/* <AreaInputSelectButtonHeader>{'State/Area'}</AreaInputSelectButtonHeader> */}
              {label && <AreaInputSelectButtonLabel>{label}</AreaInputSelectButtonLabel>}
              {!label && <AreaInputSelectButtonText>{placeholder}</AreaInputSelectButtonText>}
              {/* <ChevronDownIcon></ChevronDownIcon> */}
            </AreaInputSelectButtonCopyContainer>

            <ChevronDownIcon fill="#282828" styleProps={{ marginRight: "16px" }}></ChevronDownIcon>
          </AreaInputSelectButton>
        )}
        onToggleDropdown={() => {}}
        onSelectOption={(e) => {}}
        startSearchAnalytics={() => {}}
      ></CountriesDropdown>

      {invalid && <AreaInputError>Missing answer</AreaInputError>}
    </AreaInputContainer>
  );
};

export default BMGFAreaInput;
