import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

export const BMGFProgressContainer = styled.div`
  margin-top: 40px;
  background: #fbfbfc;
  border-radius: 10px;

  padding-top: 20px;
  padding-left: 30px;
  padding-bottom: 10px;
  ${media.max.sm`
      margin:0px;
      padding: 0px;
      padding: 15px 15px 15px 20px;
      background: #FFFFFF;
      border: 1px solid #E7E9EF;
      border-radius:0px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      border-top:none;
      align-items: center;
}
  `}
`;

export const BMGFProgressHeader = styled.div`
  // padding: 20px 233px 30px 30px;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 16px;
`;

export const BMGFProgressStepContainer = styled.div`
  display: flex;
  // margin-left: 30px;
  align-items: center;
  margin-bottom: 20px;
  ${media.max.sm`
      margin:0px;
      margin-left:20px;
  `}
`;
export const BMGFProgressStep = styled.div<{ selected?: boolean; previous?: boolean }>`
  width: 30px;
  height: 30px;
  font-size: 12px;
  background: ${({ selected, previous }) => (selected ? (previous ? "#FBFBFC" : "#2a3390") : "#EDEDED")};
  border-radius: 15px;
  color: ${({ selected, previous }) => (selected ? (previous ? "#2A3390" : "#FFFFFF") : "#A2A3A6")};
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${({ selected, previous }) => (previous ? "1.125px solid #2A3390" : "none")};
`;

export const BMGFProgressText = styled.div<{ selected?: boolean }>`
  margin-left: 10px;
  color: ${({ selected }) => (selected ? "#2a3390" : "#A2A3A6")};
`;

export const BMGFProgressStepParent = styled.div`
  display: flex;
`;

export const BMGFProgressBackButtonContainer = styled.div``;
export const BMGFProgressBackButton = styled.button`
  background: transparent;
  border: none;
  padding: 0px;
`;
export const BMGFProgressBackButtonText = styled.span`
  font-weight: 600;
  font-size: 16px;
  color: #a2a3a6;
  margin-left: 5px;
`;
