import Link from "next/link";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

export const SpecialityCard = styled.a`
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.3s;
  margin-top: 15px;
  &:first-child {
    margin-left: 0;
  }
  /* temporary. using the media from styled grid throws a type error */
  @media screen and (min-width: 768px) {
    margin: 0;
    &:hover {
      background: ${({ theme }) => theme.colors.lightPurples.hover};
      transition: background 0.3s;
    }
  }
  && {
    height: 165px;
    background: ${({ theme }) => theme.colors.lightPurples.default};
    text-decoration: none !important;
    &:hover {
      text-decoration: none !important;
    }
    min-width: 165px;
    max-width: 165px;
    ${media.max.lg`
    min-width: calc(100% / 3 - 15px);
    max-width: calc(100% / 3 - 15px);
  `}
    ${media.max.sm`
    min-width: calc(100% / 2 - 7.5px);
    max-width: calc(100% / 2 - 7.5px);
  `}
  }
`;

export const CardTitle = styled.h3`
  margin: 0 !important;
  font-size: 1.4rem !important;
  text-align: center;
  padding: 0 10px;
  color: ${({ theme }) => theme.colors.primaryPurple} !important;
  font-weight: 600;
  line-height: 1.5 !important;
`;

export const Anchor = styled(Link)``;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
`;
