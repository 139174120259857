export const CLINICS_LIST = [
  {
    location: "Lagos, Kosofe",
    name: "IFPF Hospital",
    address: "5, Oki lane Mende, Maryland Lagos",
  },
  {
    location: "Lagos, Eti-osa",
    name: "Upsillon Care Hospital",
    address: "1 Abayomi Kukoyi Street, Osapa London, Lekki, Lagos",
  },
  {
    location: "Lagos, Eti-osa",
    name: "Medison Specialist Women's Hospital",
    address: "17A/18B Olatunji Moore Street, Off Kuboye Road, Oniru, Lekki Phase 1 Lekki, Lagos",
  },
  {
    location: "Kano, Fagge",
    name: "Divine Grace Specialist Hospital",
    address: "Khaliel Haladu Sani Street, Shago Tara, Jaba Off Airport Road, Kano",
  },
  {
    location: "Lagos, Ikorodu",
    name: "Lifeshade Hospital and Medical Facilities Ltd",
    address: "3 Oshonigbehin, Ikorodu, Lagos",
  },
  {
    location: "Lagos, Ajeromi Ifelodun",
    name: "Faleti Medical Centre",
    address: "98 Baale street, by new road bus stop , Olodi Apapa, Lagos",
  },
  {
    location: "Kano, Kano Municipal",
    name: "Barewa Hospital",
    address: "11 Zaria Road, Kano",
  },
];
