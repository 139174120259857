import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { SectionTitle } from 'TS_components/HomePage/HomePage.styled';

export const DiscountSection = styled.section``;
export const SectionTitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 15px 0 !important;
  gap: 20px;
  ${SectionTitle} {
    margin: 0 !important;
  }

  ${media.max.sm`
    justify-content: space-between;
  `}
`;

export const SectionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontsizes.body12};
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  color: ${({ theme }) => theme.colors.textDark};
  border: 1px solid ${({ theme }) => theme.colors.textDark};
  border-radius: 5px;
  padding: 0 10px;
  cursor: pointer;
  min-height: 25px;
  max-height: 25px;
  white-space: nowrap;
  transition: 0.2s ease color, 0.2s ease border-color;
`;

export const DiscountCardSection = styled.div`
  display: flex;

  // STYLES TO HIDE SCROLLBAR
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  overflow-x: scroll;
  padding-top: 15px;
  margin-left: -15px;
  padding-left: 15px;
  @media only screen and (max-width: 728px) {
    overflow-x: scroll;
  }
`;

export const DiscountCardsList = styled.div`
  display: flex;
  padding-left: 2px;
`;

export const ViewAllContainer = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;

export const ViewAllLink = styled.a`
  cursor: pointer;
  border-radius: 35px;
  background-color: #2a3390 !important;
  border: none;
  padding: 9px 16px /*rtl:12px 15px 6px 18px */;
  transform: rotate(0deg) /*rtl:rotate(180deg)*/;
  margin-bottom: 10px;
`;

export const ViewAllText = styled.div`
  color: #2a3390;
  font-size: 12px;
  font-weight: 600;
  width: 90px;
  text-align: center;
`;
export const DiscountWrapperStyles = styled.div`
  .discount-component {
    box-shadow: 0px 3px 6px rgba(85, 85, 85, 0.15) !important;
    margin: 0 !important;
  }
`;
