import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Col, Container, Row } from "styled-bootstrap-grid";
import { getRoute, Mixpanel } from "../../utils";
import CountriesDropdown from "../HomeHero/CountriesDropdown/";
import { CLINICS_LIST } from "./BMGFClinicsList";
import { IndicatorDanger, IndicatorGreen, IndicatorWarning, MapIcon, Polygon, RiskBar } from "./BMGFResult.icons";
import {
  BMGFClinicAddress,
  BMGFClinicAddressSpan,
  BMGFClinicContainer,
  BMGFClinicListParent,
  BMGFClinicLocation,
  BMGFClinicName,
  BMGFDoctorList,
  BMGFDoctorListBrowseMore,
  BMGFDoctorSelectButton,
  BMGFDoctorSelectedButton,
  BMGFDoctorSelectedButtonCopyContainer,
  BMGFDoctorSelectedButtonHeader,
  BMGFDoctorSelectedButtonText,
  BMGFDoctorsListContainer,
  BMGFResultBody,
  BMGFResultClinicContainer,
  BMGFResultClinicHeader,
  BMGFResultContainer,
  BMGFResultDoctorBody,
  BMGFResultDoctorContainer,
  BMGFResultDoctorHeader,
  BMGFResultDoctorHeaderText,
  BMGFResultDoctorSelect,
  BMGFResultDoctorSelectDropdown,
  BMGFResultDoctorSelectMap,
  BMGFResultDoctorSelectText,
  BMGFResultMessageContainer,
  BMGFResultMessageIcon,
  BMGFResultMessageText,
  BMGFResultRecommendationContainer,
  BMGFResultRecommendationText,
  BMGFResultRecommendationTitle,
  BMGFResultRiskBar,
  BMGFResultRiskContainer,
  BMGFResultRiskHigh,
  BMGFResultRiskLevelIcon,
  BMGFResultRiskLevelText,
  BMGFResultRiskLow,
  BMGFResultRiskValueContainer,
  BMGFSurveyHref,
  BMGFSurveyText,
  BMGFSurveyTextContainer,
  DoctorFilterSelectedContainer,
  ResultBarInner,
  RiskIndicator,
  RiskIndicatorWrapper,
} from "./BMGFResult.styles";

import { useRouter } from "next/router";
import { _GetPopularDoctors } from "TS_Services/Doctors";
import { _GetCitiesWithDoctors } from "TS_Services/Home";
import { _GetResultFromResultId } from "TS_Services/PregnancyRiskAssessment";

import { useQuery } from "@tanstack/react-query";

import Loader from "@/components/Loader";
import ChevronDownIcon from "TS_components/HomeHero/icons/ChevronDownIcon";
import { DoctorCard } from "../../components/Doctor";

interface DropdownSelectData {
  city?: string;
  area?: string;
}

const metaData = {
  low: {
    message: "Continue receiving antenatal care",
    percentage: "0",
    icon: "green",
    bg: "rgba(99, 190, 123, 0.1)",
  },
  medium: {
    message: "Visit the nearest doctor below within 10 days",
    percentage: "50",
    icon: "warning",
    bg: "rgba(255, 187, 61, 0.1)",
  },
  high: {
    message: "Visit the nearest doctor below within 2-5 days",
    percentage: "100",
    icon: "danger",
    bg: "rgba(215, 62, 62, 0.1)",
  },
};

const BMGFResult: FC = () => {
  const isMobile = useMediaQuery({
    maxWidth: 767,
  });

  const router = useRouter();
  const [slug, setSlug] = useState("");
  const [doctorsListing, setDoctorsListing] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState("");
  const [selectButtonCopy, SetselectButtonCopy] = useState("");
  const [cityAreaValue, setCityAreaValue] = useState<{ city?: string; area?: string }>({});
  const { locale_name, client_side_country, language_code } = useSelector(({ InitialDataS }: any) => InitialDataS);
  const initData = useSelector(({ InitialData }: any) => InitialData.initData);
  const { locale } = useRouter();
  const [resultMessage, setResultMessage] = useState("");
  const [resultIcon, setResultIcon] = useState("");
  const [resultPercentage, setResultPercentage] = useState("");
  const [resultBackground, setResultBackground] = useState("");
  const [resultData, setResultData] = useState({});

  const { data } = useQuery(
    ["cityarea", client_side_country, locale],
    () =>
      _GetCitiesWithDoctors({
        country: client_side_country,
        locale,
        slug: "obstetrician-and-gynecologist",
      }),
    {
      // initialData: homeData,
    }
  );

  const fetchDoctors = async (countryValue) => {
    // setLoading(true);
    const specialtySlug = "obstetrician+and+gynecologist".replaceAll("-", "+") + "-";
    const citySlug = countryValue && countryValue.city ? countryValue.city.replaceAll("-", "+") + "-" : "";
    const areaSlug = countryValue && countryValue.area ? countryValue.area.replaceAll("-", "+") + "-" : "";
    let tempQuery = (specialtySlug + citySlug + areaSlug).slice(0, -1);

    let res = await _GetPopularDoctors({
      country: client_side_country,
      locale,
      slug: tempQuery,
    });

    if (!slug || slug == "all") {
      setSlug("all");
    }
    //  else {
    //   setSlug();
    // }
    setCityAreaValue({ ...countryValue });
    setQuery(tempQuery);
    setDoctorsListing(res);
    // setLoading(false);
  };

  useEffect(async () => {
    let params = new URLSearchParams(window.location.search);
    let resultId = params.get("id");
    let res = await _GetResultFromResultId(resultId);
    setResultData(res);

    Mixpanel.track("BMGF Result | Page Loaded | Event", {
      "Risk Level": res?.risk_level,
    });
  }, []);

  useEffect(() => {
    let value = (resultData?.risk_level as string) || "low";
    setResultMessage(metaData[value].message);
    setResultIcon(metaData[value].icon);
    setResultPercentage(metaData[value].percentage);
    setResultBackground(metaData[value].bg);
    if (Object.keys(resultData).length) {
      setLoading(false);
    }
  }, [resultData]);

  useEffect(() => {
    let cities = data?.cities || [];
    let tempCity = "";
    let tempArea = "";
    cities.forEach((city) => {
      if (city.value == cityAreaValue?.city) {
        tempCity = city.name;
        if (cityAreaValue.area) {
          city.areas.forEach((area) => {
            if (area.value == cityAreaValue.area) {
              tempArea = area.name;
            }
          });
        }
      }
    });

    let selectButtonCopy = tempCity + (tempArea ? `, ${tempArea}` : "");
    if (!tempArea && !tempCity) {
      selectButtonCopy = "";
    }
    SetselectButtonCopy(selectButtonCopy);
  }, [query]);
  return (
    <>
      <Container className="edit-profile-container">
        <Row
          style={{
            width: `${isMobile ? "100%" : "auto"}`,
            marginRight: `${isMobile ? "0px" : "auto"}`,
            marginLeft: `${isMobile ? "0px" : "auto"}`,
          }}
          justifyContent="center"
        >
          <Col col={12} sm={10} md={9} lg={8}>
            <div className="bread-crumbs bmgf">
              <h3>
                {resultData?.name?.charAt(0).toUpperCase() + resultData?.name?.slice(1) + "'s"} {"Result"}
              </h3>
              {/* <p>
                <Link href={getRoute(client_side_country, "/")}>
                  <a>{"Home"} /</a>
                </Link>{" "}
                <Link href={getRoute(client_side_country, "/me/profile/pregnancy-risk-results")}>
                  <a>{"Pregnancy Risk Results"} /</a>
                </Link>{" "}
                <a>Pregnancy Risk Results</a>
              </p> */}
            </div>
            <BMGFResultContainer>
              <BMGFResultBody bg={resultBackground}>
                <BMGFResultMessageContainer>
                  <BMGFResultMessageIcon>
                    {resultIcon == "green" && <IndicatorGreen></IndicatorGreen>}
                    {resultIcon == "warning" && <IndicatorWarning></IndicatorWarning>}
                    {resultIcon == "danger" && <IndicatorDanger></IndicatorDanger>}
                  </BMGFResultMessageIcon>
                  <BMGFResultMessageText>
                    {resultData?.risk_level?.charAt(0).toUpperCase() + resultData?.risk_level?.slice(1)} Attention
                  </BMGFResultMessageText>
                </BMGFResultMessageContainer>
                <ResultBarInner>
                  <BMGFResultRiskContainer>
                    <BMGFResultRiskValueContainer>
                      <BMGFResultRiskLow>LOW ATTENTION</BMGFResultRiskLow>
                      <BMGFResultRiskHigh>HIGH ATTENTION</BMGFResultRiskHigh>
                    </BMGFResultRiskValueContainer>
                    <BMGFResultRiskBar>
                      <RiskBar />
                    </BMGFResultRiskBar>
                    <RiskIndicatorWrapper>
                      <RiskIndicator percentage={parseInt(resultPercentage)}>
                        <BMGFResultRiskLevelIcon>
                          <Polygon />
                        </BMGFResultRiskLevelIcon>
                        <BMGFResultRiskLevelText>Required Attention level</BMGFResultRiskLevelText>
                      </RiskIndicator>
                    </RiskIndicatorWrapper>
                  </BMGFResultRiskContainer>
                </ResultBarInner>
                <BMGFResultRecommendationContainer>
                  <BMGFResultRecommendationTitle>RECOMMENDATION</BMGFResultRecommendationTitle>
                  <BMGFResultRecommendationText>{resultData?.recommendation}</BMGFResultRecommendationText>
                </BMGFResultRecommendationContainer>
              </BMGFResultBody>
              {false && (
                <BMGFResultDoctorContainer>
                  <BMGFResultDoctorHeader>
                    <BMGFResultDoctorHeaderText>Doctors Near You</BMGFResultDoctorHeaderText>
                  </BMGFResultDoctorHeader>
                  {slug && data && (
                    <DoctorFilterSelectedContainer>
                      <CountriesDropdown
                        left={isMobile ? 15 : null}
                        areas={data.areas}
                        cities={data.cities}
                        placeholder={"Choose State and Area"}
                        position={"relative"}
                        //  value={countryValue}
                        setValue={(e: DropdownSelectData) => {
                          if (
                            (e.city && e.area !== "") ||
                            (e.city == null && e.area == "") ||
                            (e.city && e.area == null)
                          ) {
                            fetchDoctors(e);
                          }
                        }}
                        triggerer={({ labelName, ...props }) => (
                          <BMGFDoctorSelectedButton ariaExpanded={props["aria-expanded"]} {...props}>
                            {/* <PinIcon /> */}
                            <BMGFDoctorSelectedButtonCopyContainer>
                              <BMGFDoctorSelectedButtonHeader>{"State/Area"}</BMGFDoctorSelectedButtonHeader>
                              <BMGFDoctorSelectedButtonText>
                                {selectButtonCopy || locale_name}
                              </BMGFDoctorSelectedButtonText>
                            </BMGFDoctorSelectedButtonCopyContainer>
                            {/* <ChevronDownIcon></ChevronDownIcon> */}
                          </BMGFDoctorSelectedButton>
                        )}
                        onToggleDropdown={() => {}}
                        onSelectOption={(e) => {}}
                        startSearchAnalytics={() => {}}
                      ></CountriesDropdown>
                    </DoctorFilterSelectedContainer>
                  )}
                  {!slug && (
                    <BMGFResultDoctorBody>
                      <BMGFResultDoctorSelect>
                        <BMGFResultDoctorSelectMap>
                          <MapIcon></MapIcon>
                        </BMGFResultDoctorSelectMap>
                        <BMGFResultDoctorSelectText>
                          Choose a state and area to see doctors near you
                        </BMGFResultDoctorSelectText>
                        <BMGFResultDoctorSelectDropdown>
                          {data && (
                            <CountriesDropdown
                              areas={data.areas}
                              left={isMobile ? 15 : null}
                              cities={data.cities}
                              placeholder={"Choose State and Area"}
                              position={"relative"}
                              triggerer={({ labelName, ...props }) => {
                                return (
                                  <BMGFDoctorSelectButton ariaExpanded={props["aria-expanded"]} {...props}>
                                    {labelName || locale_name}
                                    <ChevronDownIcon></ChevronDownIcon>
                                  </BMGFDoctorSelectButton>
                                );
                              }}
                              //  value={countryValue}
                              setValue={(e: DropdownSelectData) => {
                                if (
                                  (e.city && e.area !== "") ||
                                  (e.city == null && e.area == "") ||
                                  (e.city && e.area == null)
                                ) {
                                  fetchDoctors(e);
                                }
                              }}
                              onToggleDropdown={(e) => {}}
                              onSelectOption={() => {}}
                              startSearchAnalytics={() => {}}
                              // left={'15px'}
                            ></CountriesDropdown>
                          )}
                        </BMGFResultDoctorSelectDropdown>
                      </BMGFResultDoctorSelect>
                    </BMGFResultDoctorBody>
                  )}
                  {slug && (
                    <BMGFDoctorsListContainer>
                      <BMGFDoctorList>
                        {doctorsListing?.listings?.slice(0, 5)?.map((listing) => (
                          <DoctorCard
                            key={listing.id}
                            showAdminView={false}
                            doctor={listing}
                            showFeatured={true}
                            mixPanelTitle="Doctor Listings"
                          />
                        ))}
                      </BMGFDoctorList>
                      <BMGFDoctorListBrowseMore
                        onClick={() => {
                          router.push(getRoute(client_side_country, `/doctors/${query}`));
                        }}
                      >
                        Browse More Doctors
                      </BMGFDoctorListBrowseMore>
                    </BMGFDoctorsListContainer>
                  )}
                </BMGFResultDoctorContainer>
              )}
              <BMGFSurveyTextContainer>
                <BMGFSurveyText>
                  Let's make things better together! Share your insights on this tool, and let's continue improving our
                  services. Click{" "}
                  <BMGFSurveyHref
                    onClick={() => {
                      Mixpanel.track("BMGF Result | Survey Clicked | Button ", {
                        "Risk Level": resultData?.risk_level,
                      });
                    }}
                    href="https://survey.zohopublic.com/zs/Gx0C0a"
                  >
                    here
                  </BMGFSurveyHref>{" "}
                  to start.
                </BMGFSurveyText>
              </BMGFSurveyTextContainer>

              <BMGFResultClinicContainer>
                <BMGFResultClinicHeader>
                  <BMGFResultDoctorHeaderText>Visit The Nearest Facility</BMGFResultDoctorHeaderText>
                </BMGFResultClinicHeader>
                <BMGFClinicListParent>
                  {CLINICS_LIST.map((clinic) => {
                    return (
                      <BMGFClinicContainer>
                        <BMGFClinicLocation>{clinic.location}</BMGFClinicLocation>
                        <BMGFClinicName>{clinic.name}</BMGFClinicName>
                        <BMGFClinicAddress>
                          <BMGFClinicAddressSpan>Address:</BMGFClinicAddressSpan> {clinic.address}
                        </BMGFClinicAddress>
                      </BMGFClinicContainer>
                    );
                  })}
                </BMGFClinicListParent>
              </BMGFResultClinicContainer>
            </BMGFResultContainer>
          </Col>
        </Row>
      </Container>
      {loading && <Loader></Loader>}
    </>
  );
};

export default BMGFResult;
