export { default as BMGF } from "./BMGF";
export { default as BMGFCode } from "./BMGFCode";
export { default as BMGFResult } from "./BMGFResult";
export { default as BlogSection } from "./BlogsSection";
export { default as DiscountsSection } from "./DiscountsSection";
export { default as DownloadAppSection } from "./DownloadAppSection";
export { default as InsuranceSection } from "./InsuranceSection";
export { default as PopluarDoctorsSection } from "./PopularDoctorsSection";
export { default as PopularSpecialitiesSection } from "./PopularSpecialitiesSection";
export { default as Timeslots } from "./Timeslots";
