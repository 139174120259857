import styled from "styled-components";

export const BooleanInputContainer = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;
export const BooleanInputQuestionHeader = styled.div`
  display: flex;
`;
export const BooleanInputQuestionNumber = styled.p`
  font-size: 16px !important;
`;
export const BooleanInputLabel = styled.span`
  font-size: 16px !important;
  font-weight: bold !important;
  margin-left: 5px !important;
`;
export const BooleanInputButton = styled.button<{ selected?: boolean }>`
  border: 1px solid ${({ selected }) => (selected ? "#2a3390" : "#c7c7c7")};
  border-radius: 5px;
  padding: 10px 30px;
  width: 96px;
  background-color: ${({ selected }) => (selected ? "#2A3390" : "#fff")};
  margin-right: 10px !important;
  color: ${({ selected }) => (selected ? "#fff !important" : "#282828 !important")};
`;

export const BooleanInputError = styled.p`
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #d73e3e !important;
  margin: 10px 0px 0px 0px !important;
`;
