import SliderArrow from 'public/images/HomePageIcons/SliderArrow.svg';
import styled from 'styled-components';

export const ArrowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  gap: 10px;
  margin: auto 0;
  cursor: pointer;
  width: 130px;
`;

export const Arrow = styled(SliderArrow)<{ direction: string }>`
  width: 42px;
  height: 40px;
  transform: ${({ direction }) => (direction === 'ar' ? 'scaleX(-1)' : 'scaleX(1)')};
`;

export const ArrowText = styled.p`
  //styleName: 12px/SemiBold;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 15px !important;
  text-align: center;
  width: 100%;
  color: #2a3390 !important;
  margin: 0 !important;
  span {
    white-space: nowrap !important;
  }
`;
