import styled from "styled-components";

export const RadioInputContainer = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;
export const RadioInputQuestionHeader = styled.div`
  display: flex;
`;
export const RadioInputQuestionNumber = styled.p`
  font-size: 16px !important;
`;
export const RadioInputLabel = styled.span`
  font-size: 16px !important;
  font-weight: bold !important;
  margin-left: 5px !important;
`;

export const RadioInputParent = styled.div`
  display: flex;
  align-items: center;
`;

export const RadioInputButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  // padding: 0px 0px 10px 0;
  border-bottom: 1px solid $athens-gray;
`;

export const RadioInputButtonDefault = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0px;
  }
  &.bmgf-radiomulti-input {
    margin-bottom: 0px !important;
  }
`;

export const RadioInputButton = styled.input`
  height: 10px;
  width: 10px;
  border: 0.85px solid #2a3390;

  &:checked {
    background: #222;
  }
`;

export const RadioInputButtonLabel = styled.label`
  padding: 0 27px;
  z-index: 9;
  font-weight: 400 !important;
  font-size: 14px !important;
  text-align: left;
  font-weight: 500;
  color: #282828;
  position: relative;
  cursor: pointer;
  top:3px;
}`;

export const RadioInputButtonCheck = styled.div<{ checked?: boolean }>`
  display: block;
  position: absolute;
  border: 1px solid #ccd2dd;
  border-radius: 100%;
  height: 17px;
  width: 17px;
  // top: 5px;
  // left: 0;
  z-index: 5;
  transition: 0.15s ease all;
  border: ${({ checked }) => (checked ? "0.85px solid #2A3390" : "0.85px solid #C7C7C7")};

  &::before {
    display: block;
    position: absolute;
    content: "";
    border-radius: 100%;
    height: 8px;
    width: 8px;
    top: 25%;
    left: 25%;
    background: ${({ checked }) => (checked ? "#2A3390" : "transparent")};
  }
`;

export const RadioInputError = styled.p`
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #d73e3e !important;
  margin: 10px 0px 0px 0px !important;
`;
