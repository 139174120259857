import useTranslation from 'intl/useTranslation';
import { useRouter } from 'next/router';
import { specPaths as popularDoctors } from 'public/assets/homePageData';
import { FC } from 'react';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { SectionTitle } from 'TS_components/HomePage/HomePage.styled';
import PopularSpecialityCard from 'TS_components/PopularSpecialityCard';
// Next line: by importing * as Icons we can programmatically get the component name by doing something like Icons['DentistIcon'] (see popularDoctors.map).
import * as Icons from './PopularSpecialities.icons';
import { CardContainer, PopularSection } from './PopularSpecialitiesSection.styles';

const PopularSpecialities: FC = () => {
  const { locale } = useRouter();
  const { t } = useTranslation();

  return (
    <PopularSection>
      <Container>
        <Row>
          <Col col={12}>
            <SectionTitle>{t('Popular Specialities')}</SectionTitle>
            <CardContainer>
              {popularDoctors.map((item) => {
                return (
                  <PopularSpecialityCard
                    key={item.mixpanel_name}
                    mixpanelName={item.mixpanel_name}
                    title={t(item.text)}
                    link={`${item[`name_${locale}`]}`}
                    Svg={Icons[item.iconName]}
                  />
                );
              })}
            </CardContainer>
          </Col>
        </Row>
      </Container>
    </PopularSection>
  );
};

export default PopularSpecialities;
