import styled from 'styled-components';

export const InsuranceCardContainer = styled.div`
  background: #ffffff;
  border: 1px solid #e7e9ef;
  width: 300px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  box-shadow: 0px 2px 4px rgba(93, 95, 101, 0.1);
  cursor: pointer;
  // owl operator is used in parent section.
  &:first-child {
    margin-left: 0;
  }
`;

export const InsuranceHeaderRow = styled.div`
  display: flex;
  padding: 15px;
  justify-content: space-between;
`;

export const InsuranceHeaderName = styled.div`
  font-weight: 700;
  font-size: 16px;
`;

export const InsuranceHeaderIcon = styled.div``;

export const InsurancePlansContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  /* gap: 10px; */
  margin: 0 10px 10px;
`;
export const InsurancePlan = styled.span<{
  readonly isShowMore?: boolean;
}>`
  border: 1px solid ${({ isShowMore }) => (isShowMore ? '#2E69C3' : '#a2a3a6')};
  border-radius: 6px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ isShowMore }) => (isShowMore ? '#2E69C3' : '#a2a3a6')};
  cursor: ${({ isShowMore }) => (isShowMore ? 'pointer' : 'auto')};
  min-height: 30px;
  max-height: 30px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

export const InsuranceEmptyNetworks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  color: #a2a3a6;
  flex: 1;
`;

export const InsuranceEmptyNetworksText = styled.div``;

export const ShowMore = styled.button`
  all: unset;
  border-radius: 6px;
  padding: 6px 8px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2e69c3;
  border: 1px solid #2e69c3;
`;
