import { Arrow, ArrowContainer, ArrowText } from './SliderViewAllArrow.styles';

interface SliderViewAllArrowProps {
  direction: string;
  text: string;
  onClick: () => void;
}

export const SliderViewAllArrow = ({ onClick, direction, text }: SliderViewAllArrowProps) => {
  return (
    <ArrowContainer onClick={onClick}>
      <Arrow direction={direction} />
      <ArrowText>{text}</ArrowText>
    </ArrowContainer>
  );
};
