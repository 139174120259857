import styled from "styled-components";

export const AreaInputContainer = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;
export const AreaInputQuestionHeader = styled.div`
  display: flex;
`;
export const AreaInputQuestionNumber = styled.p`
  font-size: 16px !important;
`;
export const AreaInputLabel = styled.span`
  font-size: 16px !important;
  font-weight: bold !important;
  margin-left: 5px !important;
`;

export const AreaInputParent = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;
export const AreaInput = styled.input`
  width: 100% !important;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  padding: 10px 0px 10px 16px;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder {
    color: #a2a3a6;
    opacity: 1;
  }

  :focus {
    outline: none !important;
    border: 1px solid #2a3390;
    border-radius: 5px;
  }
`;

export const AreaInputSubPlaceHolder = styled.span`
  margin-left: -11%;
  color: #282828 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  position: absolute;
  top: 10px;
  right: 15px;
`;

export const AreaInputError = styled.p`
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #d73e3e !important;
  margin: 10px 0px 0px 0px !important;
`;

export const AreaInputSelectButton = styled.button<{ ariaExpanded?: string; borderColor?: string }>`
  z-index: ${({ ariaExpanded }) => (ariaExpanded == "true" ? "9 !important" : "0")};
  width: 100% !important;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  padding: 10px 0px 10px 10px;
  appearance: none !important;
  outline: none !important;
  position: relative;
  left: 0;
  bottom: calc(100% + 10px);
  display: flex !important;
  align-items: center !important;
  color: #282828;
  // padding: 12px 10px !important;
  font-weight: 700;
  font-size: 13px;
  line-height: 1.5 !important;
  // color: ${({ theme }) => theme.colors.textDark};
  background-color: #fff;
  // border: 1px solid #2a3390;
  // border-radius: 7px;
  justify-content: space-between;
`;

export const AreaInputSelectButtonCopyContainer = styled.div`
  padding: 0px 5px;
  text-align: left;
`;
export const AreaInputSelectButtonHeader = styled.p`
  font-weight: 400 !important;
  font-size: 12px !important;
  color: #2a3390 !important;
  margin: 0px !important;
`;

export const AreaInputSelectButtonText = styled.p`
  font-size: 14px !important;
  line-height: 21px !important;
  color: #a2a3a6 !important;
  margin: 0px !important;
`;

export const AreaInputSelectButtonLabel = styled.p`
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #282828 !important;
  margin: 0px !important;
`;
