// import { isObjectEmpty } from "@/utils/miscUtils";
import { Mixpanel } from "@/utils";
import useTranslation from "intl/useTranslation";
import { useRouter } from "next/router";
import { FC } from "react";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "styled-bootstrap-grid";
import BlogCard from "TS_components/BlogCard";
// import { SectionTitleContainer } from 'TS_components/DiscountsSection/DiscountsSection';
import { SectionTitle } from "TS_components/HomePage/HomePage.styled";
import { HomePageData } from "types/Home";
import { BlogCardsList, BlogSection, BlogSectionText, SectionButton, SectionTitleContainer } from "./BlogSection";

interface BlogSectionProps {
  homeData: HomePageData;
}

const Blogs: FC<Partial<BlogSectionProps>> = ({ homeData }: BlogSectionProps) => {
  const { t, locale } = useTranslation();
  const { client_side_country, language_code } = useSelector(({ InitialDataS }: any) => InitialDataS);
  const { push } = useRouter();

  const trackMixpanel = (eventName, eventProps) => {
    return Mixpanel.track(eventName, eventProps);
  };

  return Boolean(homeData?.blog_posts?.length) ? (
    <BlogSection>
      <Container>
        <Row>
          <Col col={12}>
            <SectionTitleContainer>
              <SectionTitle>{t("Stay Healthy With Us")}</SectionTitle>
              <SectionButton
                onClick={() => {
                  push("https://hakeem.heliumdoc.com");
                  trackMixpanel("Homepage | Our Blog Clicked | Button", {
                    Locale: client_side_country || "qatar",
                    Language: locale.toUpperCase(),
                  });
                }}
              >
                {t("Our Blog")}
              </SectionButton>
            </SectionTitleContainer>
            <BlogSectionText>{t("Read the latest healthcare articles and doctor opinions")}</BlogSectionText>
            <BlogCardsList>
              {homeData?.blog_posts.slice(0, 3).map((blog, index) => {
                return (
                  <BlogCard
                    data={blog}
                    onClick={() => {
                      trackMixpanel("Homepage | Blog Article Clicked | Card", {
                        Locale: client_side_country || "qatar",
                        Language: locale.toUpperCase(),
                        "Blog Title": blog.title,
                      });
                    }}
                  />
                );
              })}
            </BlogCardsList>
          </Col>
        </Row>
      </Container>
    </BlogSection>
  ) : (
    <></>
  );
};

export default Blogs;
