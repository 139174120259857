export const cities = [
  {
    name: "Abia",
    value: "Abia",
    name_en: "Abia",
    areas: [
      {
        name: "Aba North",
        name_en: "Aba North",
        value: "Aba North",
      },
      {
        name: "Aba South",
        name_en: "Aba South",
        value: "Aba South",
      },
      {
        name: "Arochukwu",
        name_en: "Arochukwu",
        value: "Arochukwu",
      },
      {
        name: "Bende",
        name_en: "Bende",
        value: "Bende",
      },
      {
        name: "Ikwuano",
        name_en: "Ikwuano",
        value: "Ikwuano",
      },
    ],
  },
  {
    name: "Adamawa",
    value: "Adamawa",
    name_en: "Adamawa",
    areas: [
      {
        name: "Demsa",
        name_en: "Demsa",
        value: "Demsa",
      },
      {
        name: "Fufore",
        name_en: "Fufore",
        value: "Fufore",
      },
      {
        name: "Ganye",
        name_en: "Ganye",
        value: "Ganye",
      },
      {
        name: "Girei",
        name_en: "Girei",
        value: "Girei",
      },
      {
        name: "Gombi",
        name_en: "Gombi",
        value: "Gombi",
      },
    ],
  },
];

export const states = [
  {
    id: 25,
    name: "Lagos",
    countryId: 566,
  },
  {
    id: 20,
    name: "Kano",
    countryId: 566,
  },
];
