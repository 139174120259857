import useTranslation from '@/intl/useTranslation';
import { TabButton, TabsWrapper } from './CountriesDropdown.styled';

type Props = {
  selectedTab: 'city' | 'area';
  handleOnClickTabs: (type: 'city' | 'area') => void;
};

export default function CityAreaTabs({ selectedTab, handleOnClickTabs }: Props) {
  const { t } = useTranslation();
  return (
    <TabsWrapper role="tablist" selectedTab={selectedTab}>
      <TabButton
        data-cy="city-tab"
        onClick={(e) => {
          e.preventDefault();
          handleOnClickTabs('city');
        }}
        isActive={selectedTab === 'city'}
        aria-controls="city"
        role="tab"
      >
        {t('Step 1')}
      </TabButton>
      <TabButton
        data-cy="area-tab"
        onClick={(e) => {
          e.preventDefault();
          handleOnClickTabs('area');
        }}
        isActive={selectedTab === 'area'}
        aria-controls="area"
        role="tab"
      >
        {t('Step 2')}
      </TabButton>
    </TabsWrapper>
  );
}
