import useTranslation from 'intl/useTranslation';
import { Dispatch, FC, SetStateAction } from 'react';
import { HomePageFilterBody, HomePageFilterContainer } from './HomePageFilters';

interface HomePageFilterProps {
  filters: string[];
  callBack?: Dispatch<SetStateAction<string>>;
  activeFilter: string;
  translated?: boolean;
}
// translated key is due to some values received always in english while others received in arabic if locale is ar (server response)
// maybe we should find a way to streamline this
const HomePageFilters: FC<HomePageFilterProps> = ({
  filters,
  callBack,
  activeFilter,
  translated,
}: HomePageFilterProps) => {
  const { t } = useTranslation();

  return (
    <HomePageFilterContainer>
      {filters.map((filter) => {
        return (
          <HomePageFilterBody
            data-cy="home-page-quick-filter"
            active={activeFilter === filter}
            onClick={() => callBack(filter)}
          >
            {translated ? filter : t(filter)}
          </HomePageFilterBody>
        );
      })}
    </HomePageFilterContainer>
  );
};

export default HomePageFilters;
