import Image from "next/image";
import Book from "public/images/HomePageIcons/Book.svg";
import DoctorCardClinic from "public/images/HomePageIcons/DoctorCardClinic.svg";
import DoctorCardPrice from "public/images/HomePageIcons/DoctorCardPrice.svg";
import Languages from "public/images/HomePageIcons/Languages.svg";
import Lightning from "public/images/HomePageIcons/Lightning.svg";
import Video from "public/images/HomePageIcons/VideoIcon.svg";
import styled from "styled-components";

export const DoctorCard = styled.div`
  display: flex;
  flex-direction: column !important;
  align-items: flex-start !important;
  padding: 10px;
  width: 320.13px;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(85, 85, 85, 0.15);
  border-radius: 5px;
`;

export const DoctorCardGrouping = styled.div<{ noGap?: boolean; margin?: string; fillContainer?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ noGap }) => (noGap ? "0" : "10px")};
  margin: ${({ margin }) => margin || "0"};
  min-width: ${({ fillContainer }) => (fillContainer ? "100%" : "0")};
`;

// special case for the section with the doctor name and rating to give margin.
export const DoctorInfoGrouping = styled(DoctorCardGrouping)`
  gap: 0;
  margin-left: 10px !important;
`;

export const NextImage = styled(Image)`
  width: 74px;
  height: 74px;
  border-radius: 50%;
`;

export const DoctorImageContainer = styled.div`
  min-width: 74.23px !important;
  max-width: 74.23px !important;
  min-height: 74.23px !important;
  max-height: 74.23px !important;
  border: 2px solid #ffffff;
  border-radius: 50%;
  box-shadow: 0px 2.78371px 5.56742px rgba(85, 85, 85, 0.15);
`;

export const VideoIcon = styled(Video)`
  width: 12px;
  height: 7px;
  margin: -1px -1px 0 0;
`;

export const VideoIconContainer = styled.span`
  font-size: 9.28px !important;
  font-weight: 700 !important;
  line-height: 12px !important;
  height: 19.4px;
  width: 77px;
  border-radius: 9.74299144744873px;
  display: flex;
  justify-content: space-around;
  padding: 5px;
  gap: 4px;
  align-items: center;
  color: #ffffff;
  background: #2a3390 !important;
  padding: 5px;
  border-radius: 10px;
`;

export const PopularTag = styled.span`
  height: 21.42px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600 !important;
  font-size: 11.1348px !important;
  line-height: 14px;
  color: #2a3390;
  border: solid 1px #2a3390;
  border-radius: 5px;
  padding: 0 5px;
`;

export const OfferTag = styled(PopularTag)`
  border: #f81e46 solid 1px;
  color: #f81e46;
  width: auto;
  padding: 0 5px;
`;

export const TagsWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

export const ImageInfoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

export const DoctorName = styled.h3`
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #282828;
  margin: 3px 0 0 0 !important;
`;

export const DoctorSpeciality = styled.span`
  font-weight: 400 !important;
  font-size: 14px !important;
  color: ${({ theme }) => theme.colors.textDarkGray};
`;

export const CredentialsItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 5px;
  flex-wrap: wrap;
  font-size: 1.3rem !important;
`;

export const CredentialsContent = styled.span<{ size?: string; inline?: boolean }>`
  display: ${({ inline }) => (inline ? "inline" : "inline-block")};
  font-weight: 400 !important;
  font-size: ${({ size }) => size || "13px"} !important;
  line-height: 21px;
  color: #a2a3a6 !important;
  max-width: 275px;
  margin: 0 !important;
`;

export const LinkInnerSpan = styled.span`
  color: #a2a3a6 !important;
`;

export const CTAContainer = styled.div`
  display: flex;
  align-items: space-between;
  * + * {
    margin-left: 5px;
  }
  width: 100%;
`;

export const ProfileLink = styled.a<{ fillContainer?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: none !important;
  border: 0.927904px solid #48bfc0;
  border-radius: 5px;

  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 16px !important;
  height: 46px;
  width: ${({ fillContainer }) => (fillContainer ? "100%" : "97px")};
  text-decoration: none !important;
  color: #48bfc0 !important;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    transition: all 0.3s;
    background: #48bfc0 !important;
    color: #fff !important;
  }
`;

export const BookAppointmentLink = styled.a`
  width: 198.86px;
  height: 46px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #307df6 !important;
  color: #fff !important;
  text-decoration: none !important;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s;
  overflow: hidden;
  &:hover {
    transition: all 0.3s;
    background: #3783fc !important;
  }
`;

export const BookAppointmentText = styled.span`
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  display: flex;
  align-items: center;
`;

export const LightningIcon = styled(Lightning)`
  width: 17px;
  height: 17px;
  margin-right: 4px;
`;

export const AvailabilityText = styled.span`
  font-size: 10px !important;
  font-weight: 400 !important;
  white-space: nowrap;
  text-align: center;
`;

export const Note = styled.p`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 0 5px 0 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 15px !important;
  color: #555555 !important;
`;

export const ClinicLink = styled.a`
  color: ${({ theme }) => theme.colors.generalLink};
  font-size: 1.3rem !important;
  cursor: pointer;
  display: inline;
`;

export const CTAGrouping = styled.div`
  margin: auto 0 0 0 !important;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const NormalBookingLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  background: none !important;
  border-radius: 5px;

  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 16px !important;
  height: 46px;
  width: 198px;
  text-decoration: none !important;
  cursor: pointer;

  background: #48bfc0 !important;
  color: #fff !important;
  gap: 5px;
`;

export const BookIcon = styled(Book)`
  height: 16px;
  width: 16px;
  margin: -4px 0 0 0 !important;
`;

export const DoctorClinicIcon = styled(DoctorCardClinic)`
  width: 15px;
  height: 14px;
  margin: 3px 0 0 0 !important;
`;

export const DoctorPriceIcon = styled(DoctorCardPrice)`
  width: 13px;
  height: 15px;
  margin: 3px 0 0 2px !important;
`;

export const LanguageIcon = styled(Languages)`
  height: 18.5px;
  width: 17.5px;
  margin: 1px 0 0 -3px !important;
`;

export const DoctorCardLink = styled.a`
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  padding: 0 !important;
  cursor: pointer;
  text-decoration: none !important;
`;
