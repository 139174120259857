import { useRouter } from "next/router";
import { useState } from "react";
import { ListingDoctor } from "types/Doctors";

export const useDoctorData = (doctorData: ListingDoctor) => {
  const doctorInfo = {
    name: `${doctorData.title} ${doctorData.name}`,
    name_en: `${doctorData.name_en}`,
    rating: doctorData.rating,
    specialization: doctorData.specialization,
    slug: doctorData.slug,
  };

  const doctorCredentials = {
    languages: doctorData.languages,
    clinics: doctorData.all_clinics,
    startingPrice: doctorData.all_clinics[0].consultation_range?.value.split("-")[0],
    name: doctorData.name,
    name_en: `${doctorData.name_en}`,
  };

  const CTAData = {
    slug: doctorData.slug,
    name: doctorData.name,
    name_en: doctorData.name_en,
    rating: doctorData.rating,
    numreviews: doctorData.num_reviews,
    startingPrice: doctorData.all_clinics[0].consultation_range?.value.split("-")[0],
    videoVisit: doctorData.does_video,
    specialization: doctorData.specialization,
    bookingTypes: doctorData.visit_types,
    visit_types: doctorData.visit_types,
    insurance: Boolean(doctorData.all_insurances?.length > 0),
    nextAvailable: doctorData.next_available,
    bookable: doctorData.bookable,
  };

  return { doctorInfo, doctorCredentials, CTAData };
};

export const useFilters = (paths) => {
  const { locale } = useRouter();
  const [filters, setFilters] = useState({ name: paths[0].name, slug: paths[0][`name_${locale}`] });
};
