/**
 * this file contains icons as react components for the popular specialities section in the home page.
 * It's extracted from the main styles file to allow for programmatic selection of icon based on a data object by treating this file as an object of modules.
 */
import Dentist from 'public/images/HomePageIcons/Dentist.svg';
import SkinDoctor from 'public/images/HomePageIcons/Dermatologist.svg';
import GeneralDoctor from 'public/images/HomePageIcons/General Doctor.svg';
import OBGYN from 'public/images/HomePageIcons/OBGYN.svg';
import EyeDoctor from 'public/images/HomePageIcons/Opthamologist.svg';
import Pediatrician from 'public/images/HomePageIcons/Pediatrician.svg';
import styled from 'styled-components';

export const OBGYNIcon = styled(OBGYN)``;

export const DentistIcon = styled(Dentist)``;

export const EyeDoctorIcon = styled(EyeDoctor)``;

export const GeneralDoctorIcon = styled(GeneralDoctor)``;

export const PediatricianIcon = styled(Pediatrician)``;

export const SkinDoctorIcon = styled(SkinDoctor)``;
