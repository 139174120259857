import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 75px;
  ${media.max.md`
    margin-bottom: 55px;
  `}
`;

export const CardsContainer = styled.div`
  display: flex;
  width: 100%;
  /* gap: 10px; */
  * + * {
    margin-left: 10px;
  }
  padding: 0 0 0 0;
  overflow: scroll;
  margin-top: 15px;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  height: 100%;
`;

export const ArrowBtn = styled.div<{
  readonly isDisabled?: boolean;
}>`
  line-height: 0;
  transform: none /* rtl: rotate(180deg) */;
  &.section-left-arrow {
    transform: rotate(180deg) /* rtl: none */;
  }
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.2 : 1)};
  transition: 0.3s ease opacity;
`;
export const CarouselHeader = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: -40px;
  right: 0;
  gap: 15px;
  ${media.max.md`
    display: none;
  `}
`;
export const CarouselWrapper = styled.div`
  position: relative;
  margin-left: -15px;
  /* chrome and chromium based */
  .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
  }
  .react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .scroll-container {
    height: 100%;
    padding: 15px;
  }
  .scroll-item {
    margin-left: 15px;
    &:first-child {
      margin-left: 0;
    }
    display: flex;
    align-items: center;
    & > * {
      height: 100%;
    }
    .view-all-btn {
      height: auto;
    }
  }
  .react-horizontal-scrolling-menu--arrow-left,
  .react-horizontal-scrolling-menu--arrow-right {
    position: relative;
  }
`;
