import styled from "styled-components";

export const SelectButton = styled.button<{ position?: string }>`
  appearance: none !important;
  border: none !important;
  outline: none !important;
  position: ${({ position }) => (position ? position : "absolute")};
  left: 0;
  bottom: calc(100% + 10px);
  display: flex !important;
  align-items: center !important;
  min-width: 90px;
  min-height: 32px;
  max-height: 32px;
  padding: 4px 8.5px !important;
  font-size: 1.6rem;
  text-align: left;
  gap: 7px;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: ${({ theme }) => theme.colors.textDark};
  background: ${({ theme }) => theme.colors.white200};
  border-radius: 5px;
  span {
    margin-right: auto;
    margin-bottom: 0 /* rtl: -4px */;
  }
`;
export const TabsWrapper = styled.nav<{
  readonly selectedTab: "city" | "area";
}>`
  min-height: 45px;
  max-height: 45px;
  display: flex !important;
  align-items: center;
  border-bottom: 1px solid #e7e9ef;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateX(${({ selectedTab }) => (selectedTab === "city" ? 0 : "100%")});
    width: 50%;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.textDark};
    transition: 0.3s ease transform;
  }
`;
export const TabButton = styled.a<{
  readonly isActive?: boolean;
}>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  font-size: 1.4rem;
  font-weight: 700;
  color: ${({ theme, isActive }) => (isActive ? theme.colors.textDark : theme.colors.textGray)} !important;
  text-decoration: none !important;
  cursor: pointer;
  transition: 0.3s ease color;
`;
