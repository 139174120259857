import { FC } from "react";
import {
  TextInput,
  TextInputContainer,
  TextInputError,
  TextInputLabel,
  TextInputParent,
  TextInputQuestionHeader,
  TextInputQuestionNumber,
  TextInputSubPlaceHolder,
} from "./BMGFTextInput.styles";

interface BMGFTextInputProps {
  questionNumber: string;
  questionNumberToShow: string;
  questionText: string;
  value: string;
  placeholder?: string;
  updateAnswers: (data: any) => void;
  subPlaceHolder?: string;
  type: string;
  maxValue?: number;
  minValue?: number;
  invalid?: boolean;
  options?: any;
  hideQuestionNumber?: boolean;
}

const BMGFTextInput: FC<BMGFTextInputProps> = ({
  questionNumber,
  questionText,
  questionNumberToShow,
  value,
  placeholder,
  updateAnswers,
  subPlaceHolder,
  type,
  maxValue,
  minValue,
  invalid,
  options,
  hideQuestionNumber = false,
}) => {
  return (
    <TextInputContainer>
      <TextInputQuestionHeader>
        <TextInputQuestionNumber>
          {hideQuestionNumber ? " " : questionNumberToShow + "."}
          <TextInputLabel>{questionText}</TextInputLabel>
        </TextInputQuestionNumber>
      </TextInputQuestionHeader>

      <TextInputParent>
        <TextInput
          onWheel={(e) => {
            if (type == "number") {
              e.target.blur();
            }
          }}
          type={type}
          placeholder={placeholder}
          value={value}
          min={options ? options[0] : null}
          max={options ? options[1] : null}
          onChange={(e) => {
            if (
              options &&
              (parseInt(e.target.value) < options[0] || parseInt(e.target.value) > options[options.length - 1])
            ) {
              return;
            } else {
              updateAnswers({ [questionNumber]: e.target.value });
            }
          }}
        ></TextInput>
        {subPlaceHolder && <TextInputSubPlaceHolder>{subPlaceHolder}</TextInputSubPlaceHolder>}
      </TextInputParent>
      {invalid && <TextInputError>Missing answer</TextInputError>}
    </TextInputContainer>
  );
};

export default BMGFTextInput;
