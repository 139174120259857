import { FC } from "react";
import {
  BMGFCalendarInputContainer,
  DateInputContainer,
  DateInputError,
  DateInputLabel,
  DateInputQuestionHeader,
  DateInputQuestionNumber,
} from "./BMGFDateInput.styles";

import dayjs from "dayjs";
import isEmpty from "lodash/isEmpty";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarIcon } from "./BMGFDateInput.icons";

interface BMGFTextInputProps {
  questionNumber: string;
  questionNumberToShow: string;
  questionText: string;
  value: string;
  placeholder?: string;
  updateAnswers: (data: any) => void;
  subPlaceHolder?: string;
  type: string;
  maxValue?: number;
  invalid?: boolean;
}

const BMGFDateInput: FC<BMGFTextInputProps> = ({
  questionNumber,
  questionText,
  questionNumberToShow,
  value,
  placeholder,
  updateAnswers,
  subPlaceHolder,
  type,
  maxValue,
  invalid,
}) => {
  let tempVal = value && !isEmpty(value) && "" + dayjs(value).format("DD MMM YYYY");

  const options = {
    className: "form-control bmgf-date",
    showMonthDropdown: true,
    showYearDropdown: true,
    scrollableYearDropdown: true,
    maxDate: new Date(),
    yearDropdownItemNumber: 100,
    placeholderText: tempVal ? tempVal : "Click here to choose date",
    onChange: (date) => {
      updateAnswers({ [questionNumber]: dayjs(date).format("DD MMM YYYY") });
      date;
    },
    onFocus: (e) => {
      const { target } = e;
      if (target) {
        target.readOnly = true;
        target.blur();
      }
    },
  };

  return (
    <DateInputContainer selected={value && !isEmpty(value)}>
      <DateInputQuestionHeader>
        <DateInputQuestionNumber>
          {questionNumberToShow}.<DateInputLabel>{questionText}</DateInputLabel>
        </DateInputQuestionNumber>
      </DateInputQuestionHeader>
      <BMGFCalendarInputContainer>
        <CalendarIcon />
        <DatePicker {...options} />
      </BMGFCalendarInputContainer>

      {invalid && <DateInputError>Missing answer</DateInputError>}
    </DateInputContainer>
  );
};

export default BMGFDateInput;
