import { FC, useEffect, useState } from "react";
import { Container } from "styled-bootstrap-grid";
import {
  BMGFCodeContainer,
  BMGFCodeHeader,
  BMGFCodeHeading,
  BMGFCodeHeadingText,
  BMGFCodeInputContainer,
  BMGFCodeInputParent,
  BMGFCodeInputSubText,
  BMGFCodeValidateButton,
  BMGFCodeValidateButtonContainer,
} from "./BMGFCode.styles";

import runToast from "TS_components/HomeHero/CommonToast";

import ReactCodeInput from "react-code-input";

import { Loader } from "@/components/Common";
import { Mixpanel } from "@/utils";
import { _GetBMGFCode } from "TS_Services/PregnancyRiskAssessment";
import { useSelector } from "react-redux";

interface BMGFCodeProps {}
const BMGFCode: FC<BMGFCodeProps> = () => {
  const [code, setCode] = useState("");
  const [fieldsLength, setFieldsLength] = useState(7);
  const [isLoading, setIsLoading] = useState(false);
  const [isCodeTypingStarted, setIsCodeTypingStarted] = useState(false);

  let InitialData = useSelector(({ InitialData }) => InitialData.initData);

  useEffect(() => {
    if (InitialData) {
      Mixpanel.track("BMGF Validation | Page Loaded | Event", {
        "is Logged In": InitialData?.user_is_authenticated,
      });
    }
  }, [InitialData]);

  const validate = async () => {
    if (code.length != fieldsLength) {
      return;
    }
    Mixpanel.track("BMGF Validation | Validate Button Clicked | Button", {
      "is Logged In": InitialData?.user_is_authenticated,
    });

    setIsLoading(true);
    let res = await _GetBMGFCode(code);
    if (res.found) {
      Mixpanel.track("BMGF Validation | Validation Successful | Event", {
        "is Logged In": InitialData?.user_is_authenticated,
      });
      window.sessionStorage.setItem("praUserData", JSON.stringify(res.detail));
      window.location.href = "/ng/pregnancy-risk-assessment/1";
    } else {
      Mixpanel.track("BMGF Validation | Validation Failed | Event", {
        "is Logged In": InitialData?.user_is_authenticated,
      });
      setIsLoading(false);
      runToast.error({
        id: "bmgf-err-toast",
        title: "Validation failed",
        desc: "Try again with a different code or visit heliummum.com to sign up and get a code",
        onCloseCallback: () => {},
      });
    }
  };

  const props = {
    inputStyle: {
      borderRadius: "3px",
      border: "1px solid #C7C7C7",
      background: "#F8F8F8",
      height: "45px",
      width: "35px",
      marginLeft: "10px",
      outline: "none",
      fontSize: "20px",
      textAlign: "center",
    },
  };
  return (
    <>
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          maxWidth: "initial",
        }}
      >
        <BMGFCodeContainer>
          <BMGFCodeHeader>
            <BMGFCodeHeading>Pregnancy Risk Assessment</BMGFCodeHeading>
            <BMGFCodeHeadingText>Please enter your validation code to continue to the assessment</BMGFCodeHeadingText>
          </BMGFCodeHeader>
          <BMGFCodeInputContainer>
            <BMGFCodeInputParent>
              {/* <BMGFCodeInput></BMGFCodeInput>
              <BMGFCodeInput></BMGFCodeInput>
              <BMGFCodeInput></BMGFCodeInput>
              <BMGFCodeInput></BMGFCodeInput>
              <BMGFCodeInput></BMGFCodeInput>
              <BMGFCodeInput></BMGFCodeInput>
              <BMGFCodeInput></BMGFCodeInput> */}

              <ReactCodeInput
                onChange={(e) => {
                  if (!isCodeTypingStarted) {
                    Mixpanel.track("BMGF Validation | Started Entering Code | Event", {
                      "is Logged In": InitialData?.user_is_authenticated,
                    });
                    setIsCodeTypingStarted(true);
                  }
                  setCode(e.toUpperCase());
                }}
                name="bmgfcodeinput"
                type="text"
                fields={fieldsLength}
                {...props}
              ></ReactCodeInput>
              {/* <VerificationInput placeholder="" inputProps={{ display: "none" }} /> */}
            </BMGFCodeInputParent>

            <BMGFCodeInputSubText>
              If you don’t have a code, you can get one by signing up on{" "}
              <a
                onClick={() => {
                  Mixpanel.track("BMGF Validation | HeliumMum Clicked | Button", {
                    "is Logged In": InitialData?.user_is_authenticated,
                  });
                }}
                href="https://heliummum.com"
              >
                heliummum.com
              </a>
            </BMGFCodeInputSubText>
          </BMGFCodeInputContainer>
          <BMGFCodeValidateButtonContainer>
            <BMGFCodeValidateButton disabled={code.length != fieldsLength} onClick={() => validate()}>
              Validate and Start Assessment
            </BMGFCodeValidateButton>
          </BMGFCodeValidateButtonContainer>
        </BMGFCodeContainer>
      </Container>
      {isLoading && <Loader></Loader>}
    </>
  );
};

export default BMGFCode;
