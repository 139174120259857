import styled from 'styled-components';

export const HomePageFilterContainer = styled.div`
  display: flex;
  /* gap: 10px; */
  div + div {
    margin-left: 10px;
  }
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  height: 100%;
`;

// todo: should be button (semantics and accessibility)
export const HomePageFilterBody = styled.div<{
  readonly active?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontsizes.body12};
  color: ${({ theme, active }) => (active ? theme.colors.primaryPurple : theme.colors.textGray)};
  border: 1px solid ${({ theme, active }) => (active ? theme.colors.primaryPurple : theme.colors.textGray)};
  border-radius: 5px;
  padding: 0 5px;
  cursor: pointer;
  min-height: 30px;
  max-height: 30px;
  white-space: nowrap;
  transition: 0.2s ease color, 0.2s ease border-color;
`;
