import { FC, useEffect, useState } from "react";

import {
  MultiInputContainer,
  MultiInputError,
  MultiInputItem,
  MultiInputItemContainer,
  MultiInputLabel,
  MultiInputQuestionHeader,
  MultiInputQuestionNumber,
} from "./BMGFMultiInput.styles";

import {
  RadioInputButton,
  RadioInputButtonCheck,
  RadioInputButtonDefault,
  RadioInputButtonLabel,
} from "../BMGFRadioInput/BMGFRadioInput.styles";

import { CheckboxInputButton, CheckboxInputButtonLabel } from "../BMGFCheckboxInput/BMGFCheckboxInput.styles";

interface BMGFMultiItemType {
  type: string;
  text: string;
}
interface BMGFMultiInputProps {
  questionNumber?: string;
  questionNumberToShow?: string;
  questionText?: string;
  value?: Array<BMGFMultiItemType>;
  updateAnswers?: (data: any) => void;
  type: string;
  items: Array<BMGFMultiItemType>;
  invalid?: boolean;
}

const BMGFMultiInput: FC<BMGFMultiInputProps> = ({
  questionNumber,
  questionNumberToShow,
  questionText,
  value = [],
  updateAnswers,
  type,
  items,
  invalid,
}) => {
  const [currentItems, setCurrentItems] = useState([]);
  useEffect(() => {
    items = items.sort((a, b) => {
      if (a.type == "checkBox") {
        return -1;
      } else {
        return 1;
      }
    });

    setCurrentItems(items);
  }, [items]);
  const update = (e) => {
    let currentValue = [];

    if (e.type == "radio") {
      currentValue.push(e);
    } else if (e.type == "checkBox") {
      let hasRadio =
        value &&
        value?.some((val) => {
          return val.type == "radio";
        });
      if (hasRadio) {
        currentValue.push(e);
      } else {
        let hasValue =
          value &&
          value?.some((val) => {
            return val.text == e.text;
          });
        if (hasValue) {
          currentValue = value && value?.filter((val) => val.text != e.text);
        } else {
          currentValue = value || [];
          currentValue.push(e);
        }
      }
    }
    updateAnswers({ [questionNumber]: currentValue });
  };
  const RenderItems = () => {
    return (
      <>
        {currentItems.map((item, i) => {
          let element;
          if (item.type == "checkBox") {
            element = (
              <>
                {" "}
                <CheckboxInputButton
                  checked={
                    value &&
                    value?.some((val) => {
                      return val.text == item.text;
                    })
                  }
                  type={item.type}
                  name={`checkbox-${questionNumber}`}
                  onChange={() => {}}
                ></CheckboxInputButton>
                <CheckboxInputButtonLabel
                  onClick={() => {
                    update(item);
                  }}
                >
                  {item.text}
                </CheckboxInputButtonLabel>
              </>
            );
          }
          if (item.type == "radio") {
            element = (
              <>
                <RadioInputButtonDefault className="bmgf-radiomulti-input">
                  <RadioInputButton name={`radio-${questionNumber}`} type={item.type}></RadioInputButton>
                  <RadioInputButtonLabel
                    onClick={() => {
                      update(item);
                    }}
                  >
                    {item.text}
                  </RadioInputButtonLabel>
                  <RadioInputButtonCheck
                    checked={
                      value &&
                      value?.some((val) => {
                        return val.text == item.text;
                      })
                    }
                  ></RadioInputButtonCheck>
                </RadioInputButtonDefault>
              </>
            );
          }
          return (
            <MultiInputItem id={item.type + "-" + i} className="bmgf-multi-input-item">
              {element}
            </MultiInputItem>
          );
        })}
      </>
    );
  };
  return (
    <MultiInputContainer>
      <MultiInputQuestionHeader>
        <MultiInputQuestionNumber>
          {questionNumberToShow}.<MultiInputLabel>{questionText}</MultiInputLabel>
        </MultiInputQuestionNumber>
      </MultiInputQuestionHeader>
      <MultiInputItemContainer>
        <RenderItems></RenderItems>
        {invalid && <MultiInputError>Missing answer</MultiInputError>}
      </MultiInputItemContainer>
    </MultiInputContainer>
  );
};

export default BMGFMultiInput;
