type Props = {
  fill?: string;
};

export default function CrossIcon({ fill = '#FFF' }: Props) {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.9063 12C11.2188 12 11.4792 11.8958 11.6875 11.6875C11.8958 11.4792 12 11.2188 12 10.9063C12 10.5938 11.8958 10.3229 11.6875 10.0938L7.59375 6L11.6875 1.90625C11.8958 1.67708 12 1.40625 12 1.09375C12 0.78125 11.8958 0.520833 11.6875 0.3125C11.4792 0.104167 11.2188 0 10.9063 0C10.5938 0 10.3229 0.104167 10.0938 0.3125L6 4.40625L1.90625 0.3125C1.67708 0.104167 1.41667 0 1.125 0C0.833333 0 0.572917 0.104167 0.34375 0.3125C0.114583 0.520833 0 0.78125 0 1.09375C0 1.40625 0.114583 1.67708 0.34375 1.90625L4.40625 6L0.34375 10.0938C0.114583 10.3229 0 10.5938 0 10.9063C0 11.2188 0.114583 11.4792 0.34375 11.6875C0.572917 11.8958 0.833333 12 1.125 12C1.41667 12 1.67708 11.8958 1.90625 11.6875L6 7.59375L10.0938 11.6875C10.3229 11.8958 10.5938 12 10.9063 12Z"
        fill={fill}
      />
    </svg>
  );
}
