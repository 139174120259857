import { FC } from "react";
import { useMediaQuery } from "react-responsive";
import { Col } from "styled-bootstrap-grid";
import {
  BMGFProgressBackButton,
  BMGFProgressBackButtonContainer,
  BMGFProgressBackButtonText,
  BMGFProgressContainer,
  BMGFProgressHeader,
  BMGFProgressStep,
  BMGFProgressStepContainer,
  BMGFProgressStepParent,
  BMGFProgressText,
} from "./BMGFStepper.styles";

import { ChevronLeftGrey } from "./BMGFStepper.icons";
interface BMGFStepperProps {
  step: number;
  backStep?: () => void;
  questionCategories: string[];
}
const BMGFStepper: FC<BMGFStepperProps> = ({ step, backStep, questionCategories }: BMGFStepperProps) => {
  const isMobile = useMediaQuery({
    maxWidth: 767,
  });

  return (
    <>
      {isMobile && (
        <BMGFProgressContainer>
          <BMGFProgressBackButtonContainer>
            <BMGFProgressBackButton
              onClick={() => {
                if (backStep) {
                  backStep();
                }
              }}
            >
              <ChevronLeftGrey></ChevronLeftGrey>
              <BMGFProgressBackButtonText>Back</BMGFProgressBackButtonText>
            </BMGFProgressBackButton>
          </BMGFProgressBackButtonContainer>

          <BMGFProgressStepParent>
            {questionCategories.map((category, i) => {
              return (
                <BMGFProgressStepContainer>
                  <BMGFProgressStep previous={step > i + 1} selected={step > i}>
                    {i + 1}
                  </BMGFProgressStep>
                </BMGFProgressStepContainer>
              );
            })}
            {/* <BMGFProgressStepContainer>
              <BMGFProgressStep previous={step > 1} selected={step > 0}>
                1
              </BMGFProgressStep>
            </BMGFProgressStepContainer>

            <BMGFProgressStepContainer>
              <BMGFProgressStep previous={step > 2} selected={step > 1}>
                2
              </BMGFProgressStep>
            </BMGFProgressStepContainer>

            <BMGFProgressStepContainer>
              <BMGFProgressStep previous={step > 3} selected={step > 2}>
                3
              </BMGFProgressStep>
            </BMGFProgressStepContainer>

            <BMGFProgressStepContainer>
              <BMGFProgressStep selected={step > 3}>4</BMGFProgressStep>
            </BMGFProgressStepContainer> */}
          </BMGFProgressStepParent>
        </BMGFProgressContainer>
      )}
      {!isMobile && (
        <Col col={5} offset={1}>
          <BMGFProgressContainer>
            <BMGFProgressHeader>Your Progress</BMGFProgressHeader>

            {questionCategories.map((category, i) => {
              // debugger;
              return (
                <BMGFProgressStepContainer>
                  <BMGFProgressStep previous={step > i + 1} selected={step > i}>
                    {i + 1}
                  </BMGFProgressStep>
                  <BMGFProgressText selected={step > i}>{category}</BMGFProgressText>
                </BMGFProgressStepContainer>
              );
            })}

            {/* <BMGFProgressStepContainer>
              <BMGFProgressStep previous={step > 2} selected={step > 1}>
                2
              </BMGFProgressStep>
              <BMGFProgressText selected={step > 1}>Obstetric History (part 1)</BMGFProgressText>
            </BMGFProgressStepContainer>

            <BMGFProgressStepContainer>
              <BMGFProgressStep previous={step > 3} selected={step > 2}>
                3
              </BMGFProgressStep>
              <BMGFProgressText selected={step > 2}>Obstetric History (part 2)</BMGFProgressText>
            </BMGFProgressStepContainer>

            <BMGFProgressStepContainer>
              <BMGFProgressStep selected={step > 3}>4</BMGFProgressStep>
              <BMGFProgressText selected={step > 3}>Medical History</BMGFProgressText>
            </BMGFProgressStepContainer> */}
          </BMGFProgressContainer>
        </Col>
      )}
    </>
  );
};

export default BMGFStepper;
