import useTranslation from "intl/useTranslation";
import { Mixpanel } from "lib/Mixpanel";
import { useRouter } from "next/router";
import { FC, useEffect, useRef, useState } from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "styled-bootstrap-grid";
import { SectionTitle } from "TS_components/HomePage/HomePage.styled";
import { LeftArrow, RightArrow } from "TS_components/PopularDoctorsSection";
import { CarouselHeader, CarouselWrapper } from "TS_components/PopularDoctorsSection/PopularDoctorsSection.styles";
import { Insurance } from "types/Insurance";
import InsuranceCard from "../InsuranceCard";
import {
  InsuranceEmptyList,
  InsuranceSearchContainer,
  InsuranceSection,
  SectionTitleContainer,
  StyledSearchInput,
} from "./InsuranceSection";
import { SearchIcon } from "./InsuranceSection.icons";

interface InsuranceSectionProps {
  insurances: Array<Insurance>;
}

const Insurances: FC<Partial<InsuranceSectionProps>> = ({ insurances }: InsuranceSectionProps) => {
  const { t, locale } = useTranslation();
  const { client_side_country, language_code } = useSelector(({ InitialDataS }: any) => InitialDataS);
  const { push } = useRouter();
  const [insuranceList, setInsuranceList] = useState([]);
  const containerRef = useRef(null);
  const [height, setHeight] = useState(null);
  const trackMixpanel = (eventName, eventProps) => {
    return Mixpanel.track(eventName, eventProps);
  };
  const [isSearchAnalyticsFired, setIsSearchAnalyticsFired] = useState(false);

  useEffect(() => {
    setInsuranceList(insurances);
  }, [insurances]);

  const filterInsuranceList = (target) => {
    let tempArr = [];

    if (!insurances.length) {
      return;
    }
    for (let i = 0; i < insurances.length; i++) {
      if (insurances[i].name.toLowerCase().indexOf(target.value.toLowerCase()) >= 0) {
        tempArr.push({ ...insurances[i] });
      } else {
        let added = false;
        for (let j = 0; j < insurances[i].networks.length; j++) {
          let network = insurances[i].networks[j];
          if (network.name.toLowerCase().indexOf(target.value.toLowerCase()) >= 0) {
            if (!added) {
              tempArr.push({ ...insurances[i] });
              tempArr[tempArr.length - 1].networks = []; //Start from empty arr of networks
              added = true;
            }
            tempArr[tempArr.length - 1].networks.push(network);
          }
        }
      }
    }
    if (!isSearchAnalyticsFired) {
      setIsSearchAnalyticsFired(true);
      trackMixpanel("Insurance Section | Search Bar Used | Input Field", {
        "Page Type": "Home Page",
      });
    }
    setInsuranceList(tempArr);
  };

  useEffect(() => {
    if (containerRef.current) {
      let rect = containerRef.current.getBoundingClientRect();
      setHeight(rect.height);
    }
  }, [containerRef.current]);

  return (
    <InsuranceSection>
      <Container>
        <Row>
          <Col col={12}>
            <SectionTitleContainer>
              <SectionTitle>{t("Find Doctors by Insurance")}</SectionTitle>
            </SectionTitleContainer>
            <InsuranceSearchContainer>
              <SearchIcon />
              <StyledSearchInput
                onChange={(term) => {
                  filterInsuranceList(term.target);
                }}
                placeholder={t("Filter companies or plans")}
              />
            </InsuranceSearchContainer>
            <CarouselWrapper ref={containerRef} style={{ minHeight: height }}>
              {insuranceList.length ? (
                <ScrollMenu
                  RTL={locale === "ar"}
                  Header={() => (
                    <CarouselHeader>
                      <LeftArrow /> <RightArrow />
                    </CarouselHeader>
                  )}
                  scrollContainerClassName="scroll-container"
                  separatorClassName="scroll-separator"
                  itemClassName="scroll-item"
                >
                  {insuranceList.map((insurance) => {
                    return <InsuranceCard insurance={insurance}></InsuranceCard>;
                  })}
                </ScrollMenu>
              ) : (
                <InsuranceEmptyList>{t("Your search term matches with no company or plan")}</InsuranceEmptyList>
              )}
              {/* {insuranceList.length ? (
                <InsuranceCardsList>
                  {insuranceList.map((insurance) => {
                    return <InsuranceCard insurance={insurance}></InsuranceCard>;
                  })}
                </InsuranceCardsList>
              ) : (
                <InsuranceEmptyList>{t('Your search term matches with no company or plan')}</InsuranceEmptyList>
              )} */}
            </CarouselWrapper>
          </Col>
        </Row>
      </Container>
    </InsuranceSection>
  );
};

export default Insurances;
