import Discount from "@/components/discount/index";
import { DiscountTypeLinks, DiscountTypes } from "@/components/Discounts";
import { isObjectEmpty, localize } from "@/utils";
import useTranslation from "intl/useTranslation";
import { Mixpanel } from "lib/Mixpanel";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, useState } from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "styled-bootstrap-grid";
import { SliderViewAllArrow } from "TS_components/common/SilderViewAllArrow";
import { SectionTitle } from "TS_components/HomePage/HomePage.styled";
import { LeftArrow, RightArrow } from "TS_components/PopularDoctorsSection";
import {
  CarouselHeader,
  CarouselWrapper,
  Section,
} from "TS_components/PopularDoctorsSection/PopularDoctorsSection.styles";
import { DiscountDataResponse } from "types/Discount";
import HomePageFilters from "../../TS_components/HomePageFilters";

import { DiscountWrapperStyles, SectionButton, SectionTitleContainer } from "./DiscountsSection";
import { useLoadDiscounts } from "./loadDiscounts.hooks";
interface DiscountSectionProps {
  discounts: DiscountDataResponse;
  discountCategoriesData: Object;
}

const Discounts: FC<Partial<DiscountSectionProps>> = ({ discounts, discountCategoriesData }: DiscountSectionProps) => {
  const { t, locale } = useTranslation();
  const [currentCategory, setCurrentCategory] = useState<string>(t("Best Savers"));
  const { client_side_country, language_code } = useSelector(({ InitialDataS }: any) => InitialDataS);
  const { push } = useRouter();

  const { discountsList, filterLinks } = useLoadDiscounts({
    discounts,
    discountCategoriesData,
    DiscountTypes,
    DiscountTypeLinks,
  });

  const updateFilter = (category) => {
    const eventProps = {
      Locale: client_side_country || "qatar",
      Language: locale.toUpperCase(),
      "Discount Category Name": currentCategory,
    };
    trackMixpanel("Homepage | Discount Category | Quick Filter", eventProps);
    setCurrentCategory(category);
  };

  const trackMixpanel = (eventName, eventProps) => {
    return Mixpanel.track(eventName, eventProps);
  };

  const filterOutDiscounts = (list: string[]): string[] => {
    if (!list.length) return [];
    const FilteredList = list.filter((key) => {
      if (locale === "ar") return key !== "تيست كاتوجوري";
      return key !== "Test Category";
    });
    return FilteredList;
  };

  return isObjectEmpty(discountsList) ? (
    <Container>
      <Row>
        <Col col={12}>
          <Section>
            <SectionTitleContainer>
              <SectionTitle>{t("Save Big With Discounts")}</SectionTitle>
              <SectionButton
                onClick={() => {
                  const mixpanelProps = { Locale: client_side_country || "qatar", Language: locale.toUpperCase() };
                  trackMixpanel("Homepage | See All Discounts | Button", mixpanelProps);
                  push("/" + localize(`discounts`, client_side_country, language_code));
                }}
              >
                {t("See all")}
              </SectionButton>
            </SectionTitleContainer>
            <HomePageFilters
              filters={filterOutDiscounts(Object.keys(discountsList))}
              callBack={updateFilter}
              activeFilter={currentCategory}
              translated
            />
            <CarouselWrapper>
              <ScrollMenu
                RTL={locale === "ar"}
                Header={() => (
                  <CarouselHeader>
                    <LeftArrow /> <RightArrow />
                  </CarouselHeader>
                )}
                scrollContainerClassName="scroll-container"
                separatorClassName="scroll-separator"
                itemClassName="scroll-item"
              >
                {discountsList &&
                  discountsList[currentCategory]?.map((discount, index) => (
                    <DiscountWrapperStyles>
                      <Discount speciality={null} mixPanelTitle="Home Page" discount={discount} key={discount.id} />
                    </DiscountWrapperStyles>
                  ))}

                {discountsList[currentCategory].length > 4 && (
                  <Link
                    href={
                      "/" +
                      localize(`discounts/listings?${filterLinks[currentCategory]}`, client_side_country, language_code)
                    }
                  >
                    <a className="view-all-btn">
                      <SliderViewAllArrow
                        onClick={() => {
                          trackMixpanel("Homepage | View All Discounts | Arrow Button", {
                            "Discount Category Name": currentCategory,
                            Locale: client_side_country || "qatar",
                            Language: locale.toUpperCase(),
                          });
                        }}
                        text={`${t("View All")} ${currentCategory}`}
                        direction={locale}
                      />
                    </a>
                  </Link>
                )}
              </ScrollMenu>
            </CarouselWrapper>
          </Section>
        </Col>
      </Row>
    </Container>
  ) : (
    <></>
  );
};

export default Discounts;
