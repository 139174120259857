import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

export const BMGFResultContainer = styled.div`
  padding-top: 30px;
  ${media.max.sm`
   padding-top: 10px;
  `}
`;

export const BMGFResultHeader = styled.div``;

export const BMGFResultHeaderText = styled.div`
  font-weight: 800;
  font-size: 22px;
  color: #282828;
`;

export const BMGFResultBody = styled.div<{
  readonly bg?: string;
}>`
  background: ${({ bg }) => bg};
  border-radius: 10px;
  padding: 24px 24px 52px;
  margin-top: 10px;
`;
export const ResultBarInner = styled.div`
  display: flex;
  justify-content: center;
`;
export const BMGFResultMessageContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const BMGFResultMessageIcon = styled.div`
  line-height: 0;
`;

export const BMGFResultMessageText = styled.span`
  margin-left: 14.5px;
  font-weight: 700;
  font-size: 18px;
  color: #282828;
`;

export const BMGFResultRiskContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 470px;
  ${media.xs`
    max-width: 250px;
    margin-top: 20px;
  `}
`;

export const BMGFResultRiskValueContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const BMGFResultRiskHigh = styled.span`
  font-weight: 900;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  color: #d73e3e;
  text-transform: uppercase;
`;
export const BMGFResultRiskLow = styled.span`
  font-weight: 900;
  font-size: 12px;
  line-height: 15px;
  color: #63be7b;
  text-transform: uppercase;
  margin-bottom: 4px;
`;

export const BMGFResultRiskBar = styled.div`
  width: 100%;
  margin-bottom: 5px;
`;
export const BMGFResultRiskLevelIcon = styled.div`
  line-height: 0;
  margin-bottom: 3px;
`;
export const RiskIndicatorWrapper = styled.div`
  width: calc(100% - 90px);
  position: relative;
  transform: translateX(-5px);
`;
export const RiskIndicator = styled.div<{
  readonly percentage?: number;
}>`
  position: absolute;
  top: 100%;
  white-space: nowrap;
  text-align: center;
  width: 10px;
  left: ${({ percentage }) => percentage}%;
  width: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const BMGFResultRiskLevelText = styled.div`
  font-weight: 700;
  font-size: 12px;
  color: #282828;
`;

export const BMGFResultRecommendationContainer = styled.div`
  margin-top: 40px;
`;

export const BMGFResultRecommendationTitle = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  margin-bottom: 5px;
  color: #282828;
`;
export const BMGFResultRecommendationText = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #555;
`;
export const BMGFResultDoctorContainer = styled.div`
  margin-top: 50px;
`;

export const BMGFResultDoctorHeader = styled.div``;
export const BMGFResultDoctorHeaderText = styled.span`
  font-weight: 700;
  font-size: 18px;
  color: #282828;
`;

export const BMGFResultDoctorBody = styled.div`
  margin-top: 10px;
  margin-bottom: 40px;
  background: #f8f8f8;
  border-radius: 10px;
  padding: 40px 20px;
`;
export const BMGFResultDoctorSelect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BMGFResultDoctorSelectMap = styled.div``;
export const BMGFResultDoctorSelectText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 18px;
  color: #282828;
  text-align: center;
`;
export const BMGFResultDoctorSelectDropdown = styled.div`
margin-top:18px;
}`;

export const BMGFDoctorsListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DoctorFilterSelectedContainer = styled.div`
  margin: 10px 0px 15px 0px;
`;

export const BMGFDoctorList = styled.div``;

export const BMGFDoctorListBrowseMore = styled.button`
  align-self: center;
  font-size: 16px !important;
  color: #2a3390 !important;
  background: transparent;
  border: 1px solid #2a3390;
  border-radius: 5px;
  padding: 11px 15px;
  margin-top: 25px !important;
  margin-bottom: 40px !important;
`;

export const BMGFDoctorSelectButton = styled.button<{ ariaExpanded: string }>`
  z-index: ${({ ariaExpanded }) => (ariaExpanded == "true" ? "9 !important" : "0")};
  appearance: none !important;
  outline: none !important;
  position: relative;
  left: 0;
  bottom: calc(100% + 10px);
  display: flex !important;
  align-items: center !important;
  color: #282828;
  padding: 12px 10px !important;
  font-weight: 700;
  font-size: 13px;
  line-height: 1.5 !important;
  // color: ${({ theme }) => theme.colors.textDark};
  background-color: ${({ ariaExpanded }) => (ariaExpanded == "true" ? "#f8f8f8" : "#f8f8f8")};
  border: 1px solid #282828;
  border-radius: 7px;
`;

export const BMGFDoctorSelectedButton = styled.button<{ ariaExpanded?: string; borderColor?: string }>`
  z-index: ${({ ariaExpanded }) => (ariaExpanded == "true" ? "9 !important" : "0")};
  appearance: none !important;
  outline: none !important;
  position: relative;
  left: 0;
  bottom: calc(100% + 10px);
  display: flex !important;
  align-items: center !important;
  color: #282828;
  // padding: 12px 10px !important;
  font-weight: 700;
  font-size: 13px;
  line-height: 1.5 !important;
  // color: ${({ theme }) => theme.colors.textDark};
  background-color: #fff;
  border: 1px solid #2a3390;
  border-radius: 7px;
`;

export const BMGFDoctorSelectedButtonCopyContainer = styled.div`
  padding: 0px 5px;
  text-align: left;
`;
export const BMGFDoctorSelectedButtonHeader = styled.p`
  font-weight: 400 !important;
  font-size: 12px !important;
  color: #2a3390 !important;
  margin: 0px !important;
`;

export const BMGFDoctorSelectedButtonText = styled.p`
  font-weight: 700 !important;
  font-size: 13px !important;
  color: #2a3390 !important;
  margin: 0px !important;
`;

// Static clinics styles

export const BMGFResultClinicContainer = styled.div``;

export const BMGFResultClinicHeader = styled.div``;
export const BMGFResultClinicHeaderText = styled.span`
  font-weight: 700;
  font-size: 18px;
  color: #282828;
`;

export const BMGFSurveyTextContainer = styled.div`
  margin: 30px 0px;
`;

export const BMGFSurveyText = styled.p`
  font-size: 14px !important;
  font-weight: 400 !important;
  text-align: center;
  color: #282828 !important;
  margin: 0px !important;
`;

export const BMGFSurveyHref = styled.a`
  font-size: 14px !important;
  font-weight: 400 !important;
`;

export const BMGFClinicListParent = styled.div`
  margin: 10px 0px 20px 0px;
`;

export const BMGFClinicContainer = styled.div`
  background: #f8f8f8;
  border-radius: 10px;
  margin: 0px 0px 10px 0px;
`;

export const BMGFClinicLocation = styled.div`
  padding: 10px 0px 0px 10px;
  font-size: 16px;
  font-weight: 700;
  color: #282828;
`;

export const BMGFClinicName = styled.div`
  padding: 10px 0px 0px 10px;
  font-size: 14px;
  font-weight: 600;
  color: #282828;
`;

export const BMGFClinicAddress = styled.div`
  padding: 10px 0px 10px 10px;
  font-size: 14px;
  font-weight: 400;
  color: #282828;
`;

export const BMGFClinicAddressSpan = styled.span`
  text-decoration: underline;
`;
