import { useEffect } from 'react';
import toast from 'react-hot-toast';
import CrossIcon from '../icons/CrossIcon';
import { CloseToastBtn, ToastBody, ToastContent, ToastDesc, ToastTitle } from './CommonToast.styled';

type Props = {
  title: string;
  desc?: string;
};

type CustomProps = Props & {
  dismiss: () => void;
};

const ToastContainer = ({ title, desc, dismiss }: CustomProps) => {
  return (
    <ToastBody>
      <ToastContent>
        <ToastTitle>{title}</ToastTitle>
        {desc && <ToastDesc>{desc}</ToastDesc>}
      </ToastContent>
      <CloseToastBtn onClick={() => dismiss()} type="button">
        <CrossIcon />
      </CloseToastBtn>
    </ToastBody>
  );
};

type ToastProps = Props & {
  id: string;
  onCloseCallback?: () => void;
};

export default {
  error: ({ id, onCloseCallback, ...props }: ToastProps) => {
    return toast.error(
      (t) => {
        useEffect(() => {
          if (onCloseCallback) {
            const timeOut = setTimeout(() => {
              onCloseCallback();
            }, t.duration);
            return () => {
              clearTimeout(timeOut);
            };
          }
        }, []);

        return (
          <ToastContainer
            {...props}
            dismiss={() => {
              if (onCloseCallback) onCloseCallback();
              toast.dismiss(id);
            }}
          />
        );
      },
      {
        className: 'error-toast',
        id,
        icon: null,
      }
    );
  },
};
