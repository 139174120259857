import { localize } from '@/utils';
import Axios from 'lib/Axios';
import { GetDoctorsQueryData, GetDoctorsResponse } from 'types/Doctors';

export const _GetPopularDoctors = ({
  country,
  locale,
  slug,
  filters,
}: {
  country: string;
  locale: string;
  slug: string;
  filters?: GetDoctorsQueryData;
}) =>
  Axios.post<GetDoctorsResponse>(localize(`/doctors/${slug}`, country, locale), {
    data: filters,
  }).then(({ data }) => data);
